import { useEffect} from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from "./Helpers";

export default function Dashboard({ setActiveNav, organisationName }) { 
  const { t } = useTranslation('translation');

  useEffect(() => {
    setActiveNav('Home')
  }, [setActiveNav])

  return (
    <>
      <div className="row">
        <h1>
          {capitalizeFirstLetter(t('organisation'))}: {organisationName}
        </h1>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="card d-flex align-items-center py-4">
            <Link to="/accounts">
              {/* Manage Accounts */}
              {capitalizeFirstLetter(t('manage accounts'))}
            </Link>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card d-flex align-items-center py-4">
            <Link to="/routes">
              {/* Manage Routes */}
              {capitalizeFirstLetter(t('manage routes'))}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
