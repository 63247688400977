import React  from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'

export default function Alert({ error}) {
  Alert.propTypes = {
    error: PropTypes.object
  }
  
  Alert.defaultProps = {
    error: {}
  }

  return (
      !_.isEmpty(error) && (
        <div className="alert alert-danger d-flex align-items-center" role="alert">
          <i className="fas fa-exclamation-triangle"></i>
          <div className='ms-4'>
            { error.message }
          </div>
        </div>
      )
  )
}