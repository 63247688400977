import { useCallback, useEffect, useState } from "react";
import PoiStore from "../../../stores/PoiStore";
import MemoStore from "../../../stores/MemoStore";
import DirectionsStore from "../../../stores/DirectionsStore";
import { useTranslation } from "react-i18next";

export default function PoiButton() {
  const { t } = useTranslation()
  const [ show, setShow ] = useState(false)

  const showPois = useCallback(() => {
    MemoStore.setPaused(true)
    PoiStore.showPoi(true)
  }, [])

  const checkPois = useCallback(() => {
    const currentStepIndex = DirectionsStore.getCurrentStepIndex()
    // console.log('PoiButton - currentStepIndex: ', currentStepIndex);
    const pois = PoiStore.getPois(currentStepIndex)
    // console.log('pois: ', pois);
    setShow(pois.length > 0)
  }, [setShow])

  useEffect(() => {
    DirectionsStore.addCurrentStepChangeListener(checkPois)
    PoiStore.addPoisListener(checkPois)
    // DirectionsStore.addCurrentStepChangeListener(checkPois)

    return () => {
      DirectionsStore.removeCurrentStepChangeListener(checkPois)
      PoiStore.removePoiListener(checkPois)
      // DirectionsStore.removeCurrentStepChangeListener(checkPois)
    }
  }, [checkPois])

  return (
    <>
      <button
        onClick={showPois}
        className={`${show ? '' : 'd-none '}btn btn-primary ms-2`}
      >
        {t('show pois')}
      </button>
    </>
  )
}