import assign from 'object-assign'
import EventEmitter from 'events'

let showForm = false
let showPoi = false
let showList = false
let pois = []

const SHOW_FORM_CHANGE = 'onShowFormChange'
const SHOW_POI_CHANGE = 'onShowPoiChange'
const SHOW_LIST_CHANGE = 'onListChange'
const POI_CHANGE = 'onPoiChange'

const PoiStore = assign({}, EventEmitter.prototype, {
  setShowForm: function() {
    showForm = true
    this.emit(SHOW_FORM_CHANGE)
  },

  getShowForm: function() {
    return showForm
  },

  addToggleFormListener: function(callback) {
    this.on(SHOW_FORM_CHANGE, callback)
  },

  removeToggleFormListener: function(callback) {
    this.removeListener(SHOW_FORM_CHANGE, callback)
  },

  showPoi: function() {
    showPoi = true
    this.emit(SHOW_POI_CHANGE)
  },

  getShowPoi: function() {
    return showPoi
  },

  addShowListener: function(callback) {
    this.on(SHOW_POI_CHANGE, callback)
  },

  removeShowListener: function(callback) {
    this.removeListener(SHOW_POI_CHANGE, callback)
  },

  // addPoi: function(poi) {
  //   pois.push(poi)
  //   this.emit(POI_CHANGE)
  // },

  initPois: function(orderedPois) {
    pois = orderedPois
  },

  getPois: function(index) {
    return !pois[index] ? [] : pois[index]
  },

  removePoi: function(poi) {
    const updatedPois = pois.filter(p => p !== poi)
    this.setPois([...updatedPois])
  },

  setPois: function(index, newPois) {
    // pois = newPois ? newPois : []
    pois[index] = newPois
    this.emit(POI_CHANGE)
  },

  addPoisListener: function(callback) {
    this.on(POI_CHANGE, callback)
  },

  removePoiListener: function(callback) {
    this.removeListener(POI_CHANGE, callback)
  },

  getShowList: function() {
    return showList
  },

  setShowList: function(show) {
    showList = show
    this.emit(SHOW_LIST_CHANGE)
  },

  addShowListListener: function(callback) {
    this.on(SHOW_LIST_CHANGE, callback)
  },

  removeShowListListener: function(callback) {
    this.removeListener(SHOW_LIST_CHANGE, callback)
  }
})

export default PoiStore
