import { useRef, useEffect, useCallback } from "react"
import MemoStore from "../../../stores/MemoStore"
import { useTranslation } from "react-i18next"
import { capitalizeFirstLetter } from "../../Helpers"

export default function SpeedSlider() {
  const forwardSpeed = useRef()
  const { t } = useTranslation()

  const changeForwardSpeed = useCallback((e) => {
    const speed = e.target.value
    MemoStore.changeForwardSpeed(speed)
  }, [])

  const steps = useCallback(() => {
    const arr = []
    for (let index = 3; index < 11; index++) { arr.push(index) }
    return arr
  }, [])
  
  useEffect(() => {
    forwardSpeed.current = MemoStore.getForwardSpeed()
  }, [])

  const labelStyle = {
    fontFamily: 'Verdana, Geneva, sansSerif',
    fontWeight: 'bold',
  }

  const divStyle = {
    backgroundColor: 'rgba(231, 231, 231, .5)',
    borderRadius: '7px',
    width: '200px'
  }
  
  return (
    <>
      <div className="ms-auto me-4 p-2" style={divStyle}>
        <label htmlFor="forwardSpeed" className="form-label" style={labelStyle}>{capitalizeFirstLetter(t('speed'))}</label>
        <input type="range" className="form-range" id="forwardSpeed" min="3000" max="10000" step="1000" onChange={changeForwardSpeed} />
        <div className="d-flex">
          {steps().map(step => (
            <div key={step} className="d-flex justify-content-center" style={{ width: '25px' }}>
              <span>
                {step}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
