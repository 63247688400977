import { useState, useEffect, useCallback } from 'react'
import EmptyCard from '../Views/EmptyCard'
import CycleRouteRow from './CycleRouteRow'
import { prepareRoutes, getCountryCodeByName, capitalizeFirstLetter } from '../Helpers'
import Loader from '../Views/Loader'
import FetchError from '../Views/FetchError'
import callApi from '../../utils/ApiCall'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

// import { loginRequest } from '../../authConfig'

export default function CycleRoutesList({ name, type, routeCount, baseRouteUrl, countryCode, setActiveCountry, authType, orgUuid, reFetchCycleRoutes }) {
  const [ isLoadingCycleRoutes, setIsLoadingCycleRoutes ] = useState(true)
  const [ cycleRoutes, setCycleRoutes ] = useState([])
  const [ fetchError, setFetchError ] = useState(false)
  const { page } = useParams()
  const { t } = useTranslation('translation');

  const fetchCycleRoutes = useCallback(async() => {
    setIsLoadingCycleRoutes(true)

    const country = countryCode.toLowerCase()
    const typeName = type.toLowerCase().split(' ').join('/')
    let url
    if (typeName === 'free') {
      url = `/api/routes/free/${country}`
    } else if (authType === 'Admin') {
      url = `/api/routes/${typeName}/${country}`
    } else {
      url = `/api/${orgUuid}/routes/${typeName}/${country}`
    }

    if (page) {
      url += `/${page}`
    }

    await callApi(url)
      .then(function (response) {
        if (!response.data.cycleRoutes) {
          setIsLoadingCycleRoutes(false)
          return
        }

        const routes = prepareRoutes(response.data.cycleRoutes)
        setCycleRoutes(routes)
        setIsLoadingCycleRoutes(false)
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse);
        setIsLoadingCycleRoutes(false)
        setFetchError(true)
      });
  }, [type, countryCode, authType, orgUuid, page])

  useEffect(() => {
    // console.log(authType);

    // const pathParts = location.pathname.split('/')
    // if (getCountryCodeByName(name).toLowerCase() === pathParts[pathParts.length -1]) {
      setActiveCountry(name)
    // }

    fetchCycleRoutes()
  }, [setActiveCountry, name, authType, fetchCycleRoutes])

  let routePageLinks = []
  const pagesCount = Math.ceil(routeCount / 10)

  if (pagesCount > 1) {
    for (let i = 1; i <= pagesCount; i++) {
      let classes = 'btn'
      switch (i) {
        case 1:
          classes += ' rounded-end-0 border-end-0'
          break;
        case pagesCount:
          classes += ' rounded-start-0'
          break
        default:
          classes += ' rounded-0 border-end-0'
          break;
      }

      if (parseInt(page) === i || (!page && i === 1)) {
        classes += ' btn-secondary'
      } else {
        classes += ' btn-outline-secondary'
      }

      routePageLinks.push(
        <Link key={i} to={baseRouteUrl + '/' + i} className={classes}>
          {i}
        </Link>
      )
    }
  }

  return (
    <>
      {isLoadingCycleRoutes ? (
        <Loader />
      ) : (
        <>
          {fetchError ? (
            <FetchError />
          ) : (
            <>
              {cycleRoutes.length === 0 ? (
                <EmptyCard
                  emptyText={capitalizeFirstLetter(t('no routes found'))}
                  createUrl='/routes/new'
                  buttonText={capitalizeFirstLetter(t('add new'))}
                  showBtn={false}
                />
              ) : (
                <>
                  <table className='table table-bordered'>
                    <tbody>
                      {cycleRoutes.map(
                        (cycleRoute) =>
                          <CycleRouteRow
                            key={cycleRoute.id}
                            index={cycleRoute.id.toString()}
                            cycleRoute={cycleRoute}
                            type={type}
                            authType={authType}
                            orgUuid={orgUuid}
                            // fetchCycleRoutes={fetchCycleRoutes}
                            reFetchCycleRoutes={reFetchCycleRoutes}
                          />
                      )}
                    </tbody>
                  </table>

                  {routeCount > 10 && (
                    <div className='d-flex mb-4'>
                      {routePageLinks}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
