import { loginRequest, apiConfig } from "../authConfig";
import { msalInstance } from "../index";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

async function handleResponse(contentType, response) {
    // console.log('response: ', response);
    // console.log('contentType: ', contentType);

    if (contentType === 'blob') {
        return await response.blob().then(blob => {
            // console.log('blob: ', blob);
            // console.log(blob.type);
            return blob
        })
    } else if (contentType === 'stream') {
        return response
    } else {
        return {
            status: response.status,
            data: await response.json()
        }
    }
}

export default async function callApi(path = '', method = 'GET', payload = undefined, contentType = '', accessToken = undefined) {
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        // const response = await msalInstance.acquireTokenSilent({
        const acquireTokenSilent = msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        })
        
        await acquireTokenSilent.then(function (response) {
            accessToken = response.accessToken;
        }).catch( function (error) {
            // call acquireTokenPopup in case of acquireTokenSilent failure
            // due to interaction required
            if (error instanceof InteractionRequiredAuthError) {
                // console.log(error);
            }
            
            msalInstance.clearCache()
            msalInstance.loginRedirect(loginRequest)
        });
    }

    if (accessToken) {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);
        
        if (!contentType.includes('multipart') && !contentType.includes('stream')) {
            headers.append("Content-Type", "application/json")
        }

        if (contentType.includes('stream')) {
            // headers.append("Range", "bytes=0-499")
            headers.append("Range", "bytes=0-3000000")
            headers.append("Content-type", "arraybuffer")
        }

        const options = {
            method,
            headers
        }

        if (payload) {
            if (contentType.includes('multipart')) {
                options['body'] = payload
            } else {
                options['body'] = JSON.stringify({ data: payload })
            }
        }

        const uri = apiConfig.uri + path

        return fetch(uri, options)
            .then(async response => {
                // console.log(response);
                if (response.ok) {
                    return await handleResponse(contentType, response)
                } else {
                    return Promise.reject(response)
                }
            });
            // .catch(error => 
            //     console.log(error.json())
            // );
    }
}