import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter, communityValue, licenseType, truncateString } from '../Helpers';
import moment from "moment"

export default function OrganisationRow({ organisation, setOrganisation, setIsLoadingOrganisation }) {
  const navigate = useNavigate();

  function selectOrganisation(organisation) {
    setOrganisation(organisation)
    setIsLoadingOrganisation(false)
    localStorage.setItem('organisation-uuid', organisation.uuid)
    navigate('/')
  }

  return (
    <tr>
        <td>
          {organisation.customer_id}
        </td>
        <td>
          {capitalizeFirstLetter(communityValue(organisation.community))}
        </td>
        <td>
          {truncateString(organisation.name, 32)}
        </td>
        <td>
          <div className='d-flex align-items-center'>
            {licenseType(organisation.license_type)}
            {organisation.active ? (
              <small className='ms-auto alert bg-success text-white p-1 mb-0'>
                Actief
              </small>
            ): (
              <small className='ms-auto alert bg-warning p-1 mb-0'>
                Inactief
              </small>
            )}
          </div>
        </td>
        <td>
          {organisation.left_envelop}
        </td>
        <td>
          {/* {organisation.end_date} */}
          {moment(organisation.end_date).local().format('DD/MM/YYYY')}
        </td>
        <td>
          <button onClick={() => selectOrganisation(organisation)} className='btn btn-primary btn-sm'>
            Select
          </button>
        </td>
      </tr>
  )
}