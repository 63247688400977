import React from 'react';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../Helpers';

class RouteCategoryCard extends React.Component {
  render () {
    const { name, url, countries_count, route_count, nav_link_classes, sharedAllowed, t } = this.props

    return (
      <Link to={url} className={nav_link_classes(name, sharedAllowed)}>
        <b>{capitalizeFirstLetter(t(name.toLowerCase()))}</b>
        <small>
          {capitalizeFirstLetter(t('countries'))}: {countries_count}
        </small>
        <small>
          Routes: {route_count}
        </small>
      </Link>
    )
  }
}

export default RouteCategoryCard;
