import callApi from "../../../utils/ApiCall"
import { useCallback, useEffect, useState } from "react"
import { submitSuccess, bearingToPoint, calcDistance } from "../../Helpers"
import MemoStore from "../../../stores/MemoStore"
import { Link } from 'react-router-dom';
// import i18n from "i18next";
import { useTranslation } from 'react-i18next';
// import axios from "axios";

export default function Finish({ authType, organisation, id }) {
  const [ data, setData ] = useState({
    thankYouMessage: '',
    thankYouMessageExtra: '',
    routeDistanceMesssage: '',
    routeName: '',
    isSavingRoute: false,
    routeSaved: false
  })
  const { t } = useTranslation('translation')

  const setHeadings = useCallback((list) => {
    for (let j = 0; j < list.length; j++) {
      const p1 = list[j]
      const p2 = list[j+1]
  
      if (p2) {
        p1.heading = bearingToPoint(p1.location, p2.location)
      } else {
        p1.heading = list[j-1].heading
      }

      const prevP = list[j-1]
      if (prevP) {
        p1.distanceMoved = calcDistance(prevP.location, p1.location)
      } else {
        p1.distanceMoved = 0
      }
    }
    return list
  }, [])

  const cleanSteps = useCallback((steps) => {
    // Remove duplicte panoramas
    const duplicateIndexes = []
    for (let index = 0; index < steps.length; index++) {
      const step = steps[index]
      const nextStep = steps[index+1]
      
      if (nextStep && step.pano === nextStep.pano && nextStep.distanceMoved === 0) {
        duplicateIndexes.push(index+1)
        // Set heading
        step.heading = nextStep.heading
      }
    }

    duplicateIndexes.toReversed().forEach(index => {
      steps.splice(index, 1)
    })

    steps = setHeadings([...steps])

    return steps
  }, [setHeadings])

  const saveRouteSteps = useCallback(async () => {
    const atEnd = MemoStore.getEndStatus()
    if (!atEnd || data.isSavingRoute || data.routeSaved) return

    // const { route_id, organisation, authType } = this.props
    let url
    // !! if (data.community === 'Fr' && authType === 'Manager') {
    if (data.community === 'Fr') {
        url = `/api/v2/routes/free/${id}/create/steps`
    } else if (authType === 'Manager') {
        url = `/api/v2/${organisation.uuid}/routes/${id}/create/steps`
    } else {
        url = `/api/v2/routes/${id}/create/steps`
    }
    // console.log('data: ', data);

    if (!data.isSavingRoute) {
      setData({
        isSavingRoute: true
      })
    }

    const steps = MemoStore.getSteps();
    const cleanedSteps = cleanSteps([...steps])

    return await callApi(
        url,
        'POST',
        cleanedSteps
    )
      .then(async (response) => {
        console.log('saveRouteSteps: ', response)
        if (submitSuccess(response)) {
          const countryName = t(`country_names.${response.data.country_name.toLowerCase()}`)
          // console.log('countryName: ', countryName);
          const thankYouMessage = t('thank you create', { country_name: countryName })
          const routeName = response.data.description

          // Length of the route
          // const distance = response.data.distance / 1000
          const distance = response.data.internal_distance / 1000
          // let routeDistanceMesssage = `Length of route: ${distance}km`
          const routeDistanceMesssage = t('length of route', { distance })

          let thankYouMessageExtra
          if (response.data.community === 'Sh') {
              // thankYouMessageExtra += 'Hartelijk dank om bij te dragen aan de Memomove-community routes'
              // thankYouMessageExtra = 'Thank you for contributing to the Memomove community.'
              thankYouMessageExtra = t('thank you for contributing')
          }

          // console.log('steps saved...', data);
          
          setData({
            isSavingRoute: true,
            routeSaved: true,
            thankYouMessage,
            thankYouMessageExtra,
            routeDistanceMesssage,
            routeName,
            community: response.data.community,
            used_credits: response.data.used_credits
          })
        }
      })
      .catch(async (error) => {
        const errorResponse = await error.json()
        console.error(errorResponse)
        // const { response } = error
        // if (submitError(response)) {
        //     errorToast(response.data.message)
        // }
      })
  }, [authType, data, id, organisation, t, cleanSteps])
  
  useEffect(() => {
    MemoStore.addEndChangeListener(saveRouteSteps)

    return () => {
      MemoStore.removeEndChangeListener(saveRouteSteps)
    }
  }, [saveRouteSteps])

  const { thankYouMessage, thankYouMessageExtra, routeDistanceMesssage, routeName } = data

  return (
    <>
      {data.isSavingRoute && (
        <div className="w-100 position-absolute z-3" style={{ top: '25%' }}>
          <div className="w-25 mx-auto bg-white border rounded-3 text-center p-4">
            {data.routeSaved ? (
              <>
                <div className="">
                  <h5 className="mb-4">{thankYouMessage}</h5>
                  <b>{routeName}</b>
                  <small className="d-block mt-4 mb-2">
                    {routeDistanceMesssage}
                  </small>
                  {data.thankYouMessageExtra !== '' && (
                    <div>
                      {thankYouMessageExtra}
                    </div>
                  )}

                  {data.community === 'Pr' && (
                    <div className="badge rounded-pill text-bg-warning">
                       &euro; {data.used_credits}
                    </div>
                  )}
                </div>

                <div className="d-flex">
                  <Link to='/routes' className='mt-4 btn btn-primary mx-auto'>
                    {t('back to routes')}
                  </Link>
                </div>
              </>
            ) : (                
              <div className="w-100 text-center">
                  Saving the route steps...
                  <div className="d-block mt-4 spinner-border text-primary mx-auto" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
