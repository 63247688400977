import { useRef, useEffect, useState, useCallback } from "react"

export default function ProgressBar({ s }) {
  const [ width, setWidth ] = useState(100)
  const t = useRef(s)
  const interval = useRef()

  const progress = useCallback(() => {
    interval.current = setInterval(() => {
      t.current--
      const w = 100 / s * t.current
      setWidth(w)
    }, 1000);
  }, [s, t])

  useEffect(() => {
    if (!interval.current) {
      progress()
    }    

    return () => {
      clearInterval(interval.current)
    }
  }, [progress, interval])

  return (
    <>
      {/* <small>{t.current}s</small> */}
      <div className="progress bg-white border border-primary" role="progressbar" aria-label="Basic example" aria-valuenow={width} aria-valuemin="0" aria-valuemax="100">
        <div className="progress-bar" style={{ width: `${width}%`, height: '100%' }}></div>
      </div>
    </>
  )
}