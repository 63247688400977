import React from 'react'
import { Link } from 'react-router-dom'
import TextInput from '../Form/TextInput'
import SelectInput from '../Form/SelectInput'
import Alert from '../Form/Alert'
import languages from '../languages'
import { capitalizeFirstLetter, createSelectOptionsList } from '../Helpers'
import moment from 'moment'

class AccountForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      formData: {
        id: '',
        firstname: '',
        lastname: '',
        department: '',
        language_code: '',
        language_name: '',
        end_date: moment(this.props.organisation.end_date).format('YYYY-MM-DD'),
        organisation: '',
        email: '',
        account_type: ''
      },
      formErrors: [],
      submitLabel: '',
      loadingSubmit: false
    }

    if (this.props.type === 'New') this.state.formData.email = ''
  }

  componentDidMount () {
    if (this.props.formData) {
      // this.mountFormData(this.props.formData)
      this.updateFormData(this.props.formData)
    }
  }

  updateFormData(data) {
    this.setState({ formData: data })
  }

  handleSubmit(e) {
    e.preventDefault()

    const accountData = {
      ...this.state.formData
    }

    this.setState({loadingSubmit: true}, () => {
      this.props.handleSubmit(accountData)
      this.setState({loadingSubmit: false})
    })
  }

  handleChange(fieldName, fieldValue) {
    let updatedFormData = this.state.formData
    updatedFormData[fieldName] = fieldValue
    this.updateFormData(updatedFormData)
  }

  handleLanguageChange(fieldValue) {
    const lang = languages.find((lang) => lang.code === fieldValue )
    const newFormData = {
      ...this.state.formData,
      language_code: fieldValue,
      language_name: lang ? lang.name : ''
    }

    this.setState({ formData: newFormData })
  }

  languageCodes() {
    const { t } = this.props
    return createSelectOptionsList(languages, capitalizeFirstLetter(t('select a language code')))
  }

  errorMessage(errorFields, value) {
    // console.log(errorFields);
    if (errorFields.fields) {
      return errorFields.fields.find(error => error.field === value)
    } else {
      return []
    }
  }

  render () {
    const { submitLabel, type, formErrorFields, t } = this.props
    const { formData, loadingSubmit } = this.state

    return (
      <div className=''>
        <Alert 
          error={this.errorMessage(formErrorFields, 'organisation')}
        />

        <form className="" onSubmit={(e) => this.handleSubmit(e)}>
          <TextInput
            title={capitalizeFirstLetter(t('first name'))}
            name={'firstname'}
            value={formData.firstname}
            type='text'
            focus={true}
            readonly={loadingSubmit}
            error={this.errorMessage(formErrorFields, 'firstname')}
            handleChange={(newValue) => this.handleChange('firstname', newValue)}
          />

          <TextInput
            title={capitalizeFirstLetter(t('last name'))}
            name={'lastname'}
            value={formData.lastname}
            type='text'
            readonly={loadingSubmit}
            error={this.errorMessage(formErrorFields, 'lastname')}
            handleChange={(newValue) => this.handleChange('lastname', newValue)}
          />

          <TextInput
            title={capitalizeFirstLetter(t('department'))}
            name={'department'}
            value={formData.department}
            type='text'
            readonly={loadingSubmit}
            handleChange={(newValue) => this.handleChange('department', newValue)}
          />

          {/* <SelectInput
            title={'Account type'}
            name={'type'}
            value={formData.type}
            options={accountTypesOptionsList}
            error={this.errorMessage(formErrorFields, 'account_type')}
            handleChange={(newValue) => this.handleChange('type', newValue)}
          /> */}

          <SelectInput
            // title={'Language code'}
            title={capitalizeFirstLetter(t('language code'))}
            name={'language_code'}
            value={formData.language_code}
            options={this.languageCodes()}
            disabled={loadingSubmit}
            error={this.errorMessage(formErrorFields, 'language_code')}
            handleChange={(newValue) => this.handleLanguageChange(newValue)}
          />

          <TextInput
            // title={'Language name'}
            title={capitalizeFirstLetter(t('language name'))}
            name={'language_name'}
            value={formData.language_name}
            type='text'
            readonly={true}
          />

          { type === 'New' && (
            <TextInput
              title={'Email'}
              name={'email'}
              value={formData.email}
              type='email'
              readonly={loadingSubmit}
              error={this.errorMessage(formErrorFields, 'email')}
              handleChange={(newValue) => this.handleChange('email', newValue)}
            />
          )}

          <TextInput
            // title={'End date'}
            title={capitalizeFirstLetter(t('end date'))}
            name={'end_date'}
            value={formData.end_date}
            type='date'
            readonly={loadingSubmit}
            error={this.errorMessage(formErrorFields, 'end_date')}
            handleChange={(newValue) => this.handleChange('end_date', newValue)}
          />

          { type === 'Edit' && (
            <TextInput
              // title={'Organisation'}
              title={capitalizeFirstLetter(t('organisation'))}
              name={'organisation'}
              value={formData.organisation}
              type='text'
              readonly={true}
            />
          )}

          {/* {errorMessages.length === 0 ? ( '' ) : (
            <>
              <br />
              <div className='alert alert-danger p-2' role='alert'>
                {errorMessages.map(
                  (message) => <li>{message}</li>
                )}
              </div>
            </>
          )} */}

          <br />
          <div className='form-group'>
            {loadingSubmit ? (
              <button className='btn btn-primary' disabled>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="visually-hidden" role="status">Loading...</span>
              </button>
            ) : (
              <input type="submit" className='btn btn-primary' value={submitLabel} />
            )}
            <Link to='/accounts' className="btn btn-link">
              {capitalizeFirstLetter(t('cancel'))}
            </Link>
          </div>
        </form>
      </div>
    )
  }
}

export default AccountForm