import assign from 'object-assign'
import EventEmitter from 'events'

const VISIBILITY_CHANGED = 'onVisibilityChange'

let wakeLock = null

const WakeLockStore = assign({}, EventEmitter.prototype, {
  requestWakeLock:  async function() {
    try {
      wakeLock = await navigator.wakeLock.request('screen')

      // change up our interface to reflect wake lock active
      // const changeUI = (status = 'acquired') => {
      //   const acquired = status === 'acquired' ? true : false
      //   console.log('wakeLock status: ', acquired)
      // }
      // changeUI()

      // listen for our release event
      wakeLock.onrelease = function(ev) {
        if (process.env.REACT_APP_ENV === 'dev') console.log(ev);
      }
      // wakeLock.addEventListener('release', () => {
      //   // if wake lock is released alter the button accordingly
      //   changeUI('released');
      // });

    } catch (err) {
      // if wake lock request fails - usually system related, such as battery
      if (process.env.REACT_APP_ENV === 'dev') console.error(err)
    }
    this.emit(VISIBILITY_CHANGED)
  },
  checkWakeLock: async function() {
    if (wakeLock !== null && document.visibilityState === 'visible') {
      await WakeLockStore.requestWakeLock()
    }
  }
})

export default WakeLockStore
