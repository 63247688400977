import { useEffect, useState, useCallback } from 'react';
import MemoStore from '../../../stores/MemoStore';

export default function Pause() {
  const [ paused, setPaused ] = useState(false)
  const [ show, setShow ] = useState(true)

  const togglePause = useCallback(() => {
    MemoStore.setPaused(!paused)
    setPaused(!paused)
  }, [paused])

  const hidePause = useCallback(() => {
    const atEnd = MemoStore.getEndStatus()
    if (atEnd) setShow(false)
  }, [])
  
  useEffect(() => {  
    MemoStore.addEndChangeListener(hidePause)
  }, [hidePause])
  
  return (
    <>
      {show && (
        <div className="w-100 position-absolute my-2" style={{ zIndex: 10 }}>
          <button
            type='button'
            className={`btn ${paused ? 'btn-success' : 'btn-secondary'} d-flex align-items-center mx-auto`}
            title='Start'
            onClick={togglePause}
          >
            {paused ? (
              <>
                <i className="fas fa-play" aria-hidden="true"/>
                &nbsp;
                Resume
              </>
            ) : (
              <>
                <i className="fas fa-pause" aria-hidden="true"/>
                &nbsp;
                Pause
              </>
            )}
          </button>
        </div>
      )}
    </>
  )
}
