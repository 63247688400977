import { useTranslation } from "react-i18next"

export default function NotFound() {
  const { t } = useTranslation()

  return (
    <div className="d-flex align-items-center">
      <div className="mx-auto p-4 border rounded-3">
        <h3 className="">{ t('not found') }</h3>
      </div>
    </div>
  )
}