import React from 'react'
import { Link } from 'react-router-dom';
import Loader from '../Views/Loader';
import callApi from '../../utils/ApiCall';
import { accountTypes, submitSuccess, successToast, capitalizeFirstLetter } from '../Helpers';
import moment from 'moment';

class AccountRow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      invitingUser: false
    }
  }

  accountTypeValue(type) {
    return accountTypes.find(t => t.key === type ).value
  }

  async sendInvitation(account) {
    const that = this
    let invitationUrl
    if (this.props.authType === 'Admin') {
      invitationUrl = `/accounts/${account.uuid}/invitation/send`
    } else {
      invitationUrl = `/organisations/${account.org_uuid}/accounts/${account.uuid}/invitation/send`
    }

    this.setState({ invitingUser: true })

    await callApi(invitationUrl, 'PUT', {})
      .then(function (response) {
        if (submitSuccess(response)) {
          that.setState({ invitingUser: false }, () => {
            successToast(`Invitation sent!`)
          })
        }
      })
      .catch(async function (error) {
        // if (submitError(error)) {
        //   const response = await error.json()
        //   console.log(response);
        // }
      });
  }

  render() {
    const { account, t } = this.props
    const { invitingUser } = this.state
    const editUrl = `/accounts/${account.uuid}/edit`

    return (
      <tr>
        <td>
          {account.id}
        </td>
        <td>
          {account.firstname + ' ' + account.lastname}
        </td>
        <td>
          <div className='d-flex align-items-center'>
            {this.accountTypeValue(account.type)}
            {account.invited && (
              <>
                <div className='ms-auto badge rounded-pill bg-warning'>
                  Invited
                </div>
                {invitingUser ? (
                  <Loader />
                ) : (
                  <button onClick={() => this.sendInvitation(account)} className='ms-2 btn btn-outline-secondary btn-sm'>
                    Resend invitation
                  </button>
                )}
              </>
            )}

            {account.invitation_accepted && (
              <>
                <div className='ms-auto badge rounded-pill bg-success'>
                  Accepted
                  {account.invitation_accepted_at && (
                    <small className='ms-2'>
                      {moment(account.invitation_accepted_at).local().format('DD/MM/YYYY HH:mm:ss')}
                    </small>
                  )}
                </div>
              </>
            )}
          </div>
        </td>
        <td>
          {account.end_date}
        </td>
        <td>
          <Link to={editUrl} className='btn btn-outline-secondary btn-sm'>
            {capitalizeFirstLetter(t('edit'))}
          </Link>
        </td>
      </tr>
    )
  }
}

export default AccountRow
