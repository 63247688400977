import assign from 'object-assign'
import EventEmitter from 'events'

const DIRECTIONS_CHANGED = "onDirectionChange"
// const LEG_CHANGED = "onLegChange"
const STEPS_CHANGED = "onStepsChange"
const CURRENT_STEP_CHANGED = "onCurrentStepChange"
const STEP_DISTANCE_CHANGED = "onStepDistanceChange"
const CURRENT_POINT_CHANGED = "onCurrentPointChange"
const DIRECTION_STEPS_CHANGED = "onDirectionStepsChanged"

let directionsData = null
// let currentLeg = null
// let currentLegIndex = 0
let currentStep = null
let currentStepIndex = 0
let stepDistance = 0
let currentPointIndex = 0
let currentPoint = null
let directionSteps = null
let steps = []

const DirectionsStore = assign({}, EventEmitter.prototype, {
  setSteps: function(data) {
    steps = data
    this.emit(STEPS_CHANGED)
  },

  getSteps: function() {
    return steps
  },

  getStep: function( stepIndex ) {
    return steps[stepIndex]
  },

  addStepsChangeListener: function(callback) {
    this.on(STEPS_CHANGED, callback)
  },

  removeStepsChangeListener: function(callback) {
    this.removeListener(STEPS_CHANGED, callback)
  },

  setDirectionsData: function(data) {
    directionsData = data
    // this.setCurrentLeg(currentLegIndex)
    // this.setCurrentStep(currentStepIndex)
    // this.setCurrentPoint(currentPointIndex)
    this.emit(DIRECTIONS_CHANGED)
  },

  getDirections: function() {
    return directionsData
  },

  addDirectionsChangeListener: function(callback) {
    this.on(DIRECTIONS_CHANGED, callback)
  },

  removeDirectionsChangeListener: function(callback) {
    this.removeListener(DIRECTIONS_CHANGED, callback)
  },

  // setCurrentLeg: function() {
  //   currentLeg = directionsData.routes[0].legs[currentLegIndex]
  //   this.emit(LEG_CHANGED)
  // },

  // getCurrentLeg: function() {
  //   return currentLeg
  // },

  // addLegChangeListener: function (callback) {
  //   this.on(LEG_CHANGED, callback)
  // },

  // removeLegChangeListener: function(callback) {
  //   this.removeListener(LEG_CHANGED, callback);
  // },

  // getCurrentLegIndex: function() {
  //   return currentLegIndex
  // },

  // setNextLeg: function() {
  //   currentLegIndex++
  //   stepDistance = 0
  //   this.setCurrentLeg()
  //   currentStepIndex = 0
  //   this.setCurrentStep()
  // },

  // addCurrentLegIndexChangeListener: function(callback) {
  //   this.on(CURRENT_LEG_INDEX_CHANGED, callback)
  // },

  setCurrentStep: function(step) {
    // currentStep = directionsData.routes[0].legs[currentLegIndex].steps[currentStepIndex]
    // console.log(step);
    currentStep = step
    this.emit(CURRENT_STEP_CHANGED)
  },

  getCurrentStep: function() {
    return currentStep
  },

  setCurrentStepIndex: function(stepIndex) {
    currentStepIndex = stepIndex
  },

  getCurrentStepIndex: function() {
    return currentStepIndex
  },

  setNextStep: function() {
    currentStepIndex++
    stepDistance = 0
    this.setCurrentStep()
  },

  addCurrentStepChangeListener: function(callback) {
    this.on(CURRENT_STEP_CHANGED, callback)
  },

  removeCurrentStepChangeListener: function(callback) {
    this.removeListener(CURRENT_STEP_CHANGED, callback)
  },

  setCurrentPoint: function() {
    currentPoint = currentStep.lat_lngs[currentPointIndex]
    this.emit(CURRENT_POINT_CHANGED)
  },

  getCurrentPoint: function() {
    return { lat: currentPoint.lat(), lng: currentPoint.lng() }
  },

  getCurrentPointIndex: function() {
    return currentPointIndex
  },

  setNextPoint: function() {
    currentPointIndex++
    this.setCurrentPoint()
  },

  getNextPoint: function() {
    const nextPoint = currentStep.lat_lngs[currentPointIndex + 1]
    return { lat: nextPoint.lat(), lng: nextPoint.lng() }
  },

  addPointChangeListener: function(callback) {
    this.on(CURRENT_POINT_CHANGED, callback)
  },

  removePointChangeListener: function(callback) {
    this.removeListener(CURRENT_POINT_CHANGED, callback)
  },

  setStepDistance: function(distance) {
    stepDistance = distance
    this.emit(STEP_DISTANCE_CHANGED)
  },

  addStepDistance: function(distance) {
    stepDistance += distance
    this.emit(STEP_DISTANCE_CHANGED)
  },

  getStepDistance: function() {
    return stepDistance
  },

  resetStepDistance: function() {
    stepDistance = 0
  },

  addStepDistanceChangeListener: function(callback) {
    this.on(STEP_DISTANCE_CHANGED, callback)
  },

  removeStepDistanceChangeListener: function(callback) {
    this.removeListener(STEP_DISTANCE_CHANGED, callback);
  },

  setDirectionSteps: function(steps) {
    directionSteps = steps
    this.emit(DIRECTION_STEPS_CHANGED)
  },

  getDirectionSteps: function() {
    return directionSteps
  },

  addDirectionStepsChangeListener: function(callback) {
    this.on(DIRECTION_STEPS_CHANGED, callback)
  },

  removeDirectionStepsChangeListener: function(callback) {
    this.removeListener(DIRECTION_STEPS_CHANGED, callback)
  }
})

export default DirectionsStore