import { toast } from 'react-toastify'
import countries from '../countries'
import moment from 'moment'

export const pageLoader = (
  <div className='w-100 h-100 my-5 d-flex align-items-center justify-content-center'>
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
)

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncateString(str, num) {
  if (str.length <= num) return str
  return str.slice(0, num) + '...'
}

export function prepareAccounts(accounts) {
  return accounts.map((account) => {
    return {
      ...account,
      end_date: moment(account.end_date).local().format('DD/MM/YYYY')
    }
  })
}

export function prepareRoutes(routes) {
  if (routes === null) return []
  
  return routes.map((route) => {
    return {
      ...route,
      last_run: moment(route.last_run).local().format('DD/MM/YYYY HH:mm:ss')
    }
  })
}

export function submitSuccess(response) {
  return response.status === 200 && response.data.success
}

export function submitError(response) {
  // return response.status !== 200 && !response.data.success
  return response.status !== 200 && !response.ok
}

export function defaultToast(text) {
  return toast.info(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: "light",
  });
}

export function successToast(text) {
  return toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    // draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function errorToast(text) {
  return toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    // draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function createSelectOptionsList(input, prompt) {
  let output = input.map((line) => {
    return { key: line.code, value: line.code, name: `${line.name} - ${line.code.toUpperCase()}` }
  })
  output.unshift({ key: '', value: '', name: prompt })
  return output
}

export const accountTypes = [
  { key: 'U', value: 'User' },
  { key: 'A', value: 'Admin' },
  { key: 'M', value: 'Manager' },
]

export const accountTypesOptionsList = [
  { key: '', value: '', name: 'Select a account type' },
  { key: 'U', value: 'U', name: 'User' },
  { key: 'A', value: 'A', name: 'Admin' }
]

// export const licenseTypes = {
//   'B': 'Bronze',
//   'S': 'Silver',
//   'G': 'Gold'
// }

export function communityValue(community) {
  const communities = {
    'Fr': 'free',
    'Sh': 'shared',
    'Pr': 'private'
  }

  // console.log('community: ', community);

  return communities[community]
}

export function licenseTypes() {
  return [
    { key: 'F', value: 'Free', valueNl: 'Gratis' },
    { key: 'B', value: 'Bronze', valueNl: 'Brons' },
    { key: 'Z', value: 'Silver', valueNl: 'Zilver' },
    { key: 'G', value: 'Gold', valueNl: 'Goud' }
  ]
}

export function licenseType(type, lang = 'en') {
  let license = licenseTypes().find(license => license.key === type)
  if (license === undefined) return license

  if (lang === 'nl') {
    return license.valueNl
  } else {
    return license.value
  }
}

export function getCountryCodeByName(name) {
  const country = countries.find(country => country.name === name)
  if (country) return country.code

  return null
}

export function bearingToPoint(p1, p2) {
  const phi_1 = p1.lat * Math.PI / 180,
    phi_2 = p2.lat * Math.PI / 180;
  // const delta_gamma = (p2.lon - p1.lon) * Math.PI / 180;
  const delta_gamma = (p2.lng - p1.lng) * Math.PI / 180;

  // see http://mathforum.org/library/drmath/view/55417.html
  const y = Math.sin(delta_gamma) * Math.cos(phi_2);
  const x = Math.cos(phi_1) * Math.sin(phi_2) - Math.sin(phi_1) * Math.cos(phi_2) * Math.cos(delta_gamma);
  const theta = Math.atan2(y, x);

  return ((theta*180/Math.PI)+360) % 360;
}

// Convert Degress to Radians
function Deg2Rad(deg) {
  return deg * Math.PI / 180;
}

function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
  lat1 = Deg2Rad(lat1);
  lat2 = Deg2Rad(lat2);
  lon1 = Deg2Rad(lon1);
  lon2 = Deg2Rad(lon2);
  var R = 6371; // km
  var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
  var y = (lat2 - lat1);
  var d = Math.sqrt(x * x + y * y) * R;
  return d;
}

export function NearestLocation(latitude, longitude, locations) {
  var minDif = 99999;
  // var minDif = 99;
  var closest;

  for (let index = 0; index < locations.length; ++index) {
    var dif = PythagorasEquirectangular(latitude, longitude, locations[index].lat, locations[index].lng);
    if (dif < minDif) {
      closest = index;
      minDif = dif;
    }
  }

  return {
    index: closest,
    location: locations[closest],
  }
}

export function findClosestNumber(arr, goal) {
  return arr.reduce(function (prev, curr) {
    return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
  });
}

function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180
}

export function calcDistance(p1, p2) {
  if (p1.lat === p2.lat && p1.lng === p2.lng) return 0

  let startingLat = degreesToRadians(p1.lat);
  let startingLong = degreesToRadians(p1.lng);
  let destinationLat = degreesToRadians(p2.lat);
  let destinationLong = degreesToRadians(p2.lng);

  // Radius of the Earth in kilometers
  let radius = 6571;

  // Haversine equation
  let distanceInKilometers = Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) +
  Math.cos(startingLat) * Math.cos(destinationLat) *
  Math.cos(startingLong - destinationLong)) * radius;

  return distanceInKilometers * 1000;
}

export async function allCountries() {
  await fetch(`https://api.worldbank.org/v2/country`)
    .then(res => {
      console.log(res)
    })
    .catch(err => console.error(err))
}
