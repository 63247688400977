import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from "../Helpers";

export default function OrganisationDashboard({ organisation }) {
  const { t } = useTranslation('translation');

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <div className="card d-flex align-items-center py-4">
            <Link to="/accounts">
              {capitalizeFirstLetter(t('manage accounts'))}
            </Link>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card d-flex align-items-center py-4">
            <Link to="/routes">
              {capitalizeFirstLetter(t('manage routes'))}
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-5 row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <b>{capitalizeFirstLetter(t('organisation details'))}</b>
            </div>
            <div className="card-body">
              <div>
              <b>{capitalizeFirstLetter(t('customer id'))}: </b> {organisation.customer_id}
              </div>
              <div>
                <b>{capitalizeFirstLetter(t('address'))}: </b> {organisation.street1}, {organisation.postalcode} {organisation.city}, {organisation.country}
              </div>
              <div>
                <b>{capitalizeFirstLetter(t('license type'))}: </b>{ organisation.license_type }
              </div>
              <div>
                <b>{capitalizeFirstLetter(t('allowed accounts'))}: </b>{ organisation.allowed_accounts }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}