import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import Player from "./Player";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import WakeLockStore from '../../../stores/WakeLockStore';

export default function RoutePoiPlayer({ setActiveNav, orgUuid, authType }) {
  const { id } = useParams();
  const { t } = useTranslation('translation');

  useEffect(() => {
    setActiveNav('Routes', 'RoutePlayer')
    WakeLockStore.requestWakeLock()
    document.addEventListener('visibilitychange', WakeLockStore.checkWakeLock)
  }, [setActiveNav])

  return (
    <Fragment>
      <Player
        id={id}
        authType={authType}
        orgUuid={orgUuid}
        t={t}
      />
    </Fragment>
  )
}