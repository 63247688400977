import { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import { capitalizeFirstLetter, errorToast, successToast, submitSuccess } from '../Helpers';
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import callApi from '../../utils/ApiCall';
import Loader from '../Views/Loader';
import TextInput from '../Form/TextInput';

export default function EditCycleRoute({ setPageClasses, authType }) {
  const navigate = useNavigate()
  const [ isLoading, setIsLoading ] = useState(true)
  const [ formErrorFields, setFormErrorFields ] = useState([])
  const [ loadingSubmit, setLoadingSubmit ] = useState(false)
  const [ route, setRoute ] = useState(null)
  
  const { t } = useTranslation('translation');
  const { id } = useParams();

  useEffect(() => {
    if (!route) {
      let url
      console.log(authType);
      if (authType === 'Manager') {
        url = `/api/manager/routes/${id}`
      } else {
        url = `/api/routes/${id}`
      }

      callApi(url)
        .then(function (response) {
          setRoute(response.data)
          setIsLoading(false)
        })
        .catch(async function (error) {
          const errorResponse = error.json()
          console.error(errorResponse)
        });
    }
  }, [route, setPageClasses, authType, id, isLoading, navigate, t])

  const updateRoute = useCallback(async (e) => {
    e.preventDefault();

    setLoadingSubmit(true)

    let url
    if (authType === 'Manager') {
      url = `/api/manager/routes/${route.id}`
    } else {
      url = `/api/routes/${route.id}`
    }

    return await callApi(
      url,
      'PATCH',
      {
        description: route.description,
        default_speed: route.default_speed
      }
    ).then(function (response) {
      console.log('response: ', response);
      if (submitSuccess(response)) {
        successToast(
          t(capitalizeFirstLetter('route updated'))
        )
        navigate('/routes')
      } else {
        errorToast(
          t(capitalizeFirstLetter('error saving'))
        )
        setLoadingSubmit(false)
      }
    }).catch(async function (error) {
      const errorResponse = await error.json()
      // console.error(errorResponse);
      setFormErrorFields(errorResponse.fields)
      setLoadingSubmit(false)
    });
  }, [authType, route, navigate, t])

  const handleChange = useCallback((fieldName, fieldValue) => {
    const updatedData = { ...route }

    if (fieldName === 'default_speed') {
      updatedData[fieldName] = fieldValue / 1000
    } else {
      updatedData[fieldName] = fieldValue
    }
    setRoute(updatedData)
  }, [route])

  const errorMessage = function(errorFields, fieldName) {
    return errorFields.find(error => error.field === fieldName)
  }

  const steps = useCallback(() => {
    const arr = []
    for (let index = 3; index < 11; index++) { arr.push(index) }
    return arr
  }, [])

  const stepStyle = useCallback((step) => {
    if (step === 10) return { width: '25px' }

    return { width: 'calc((100% - 25px)/ 7)' }
  }, [])
  
  return (
    <>
      <div className='row'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/routes">Routes</Link></li>
          </ol>
        </nav>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h1>{ capitalizeFirstLetter(t('edit route')) }</h1>
          <form className="" onSubmit={(e) => updateRoute(e)}>
            <TextInput
              title={capitalizeFirstLetter(t('description'))}
              helper={capitalizeFirstLetter(t('min 20 chars'))}
              name={'description'}
              value={route.description ? route.description : ''}
              handleChange={(newValue) => handleChange('description', newValue)}
              error={errorMessage(formErrorFields, 'description')}
              focus={true}
            />

            <div className="">
              <label htmlFor="forwardSpeed" className="form-label">
                {capitalizeFirstLetter(t('default_speed'))}
              </label>
              <input type="range" className="form-range" id="forwardSpeed" min="3000" max="10000" step="1000" onChange={(e) => handleChange('default_speed', e.target.value)} />
              <div className="d-flex w-100">
                {steps().map(step => (
                  <div key={step} className="d-flex" style={stepStyle(step)}>
                    <span style={{ marginLeft: '.5rem' }}>
                      {step}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {loadingSubmit ? (
              <button className='btn btn-primary' disabled>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="visually-hidden" role="status">Loading...</span>
              </button>
            ) : (
              <div className='mt-4'>
                <input
                  type="submit"
                  className='btn btn-primary'
                  value={capitalizeFirstLetter(t('edit route')) }
                />
                <Link
                  to="/routes"
                  className='btn btn-secondary ms-2'
                >{capitalizeFirstLetter(t('cancel'))}</Link>
              </div>
            )}
          </form>
        </>
      )}
    </>
  )
}
