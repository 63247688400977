import { useEffect, useState, useCallback, useRef } from "react"
import callApi from "../../../utils/ApiCall"
import PoiStore from "../../../stores/PoiStore"
import { submitError } from "../../Helpers"
import Loader from "../../Views/Loader"
import ProgressBar from "./ProgressBar"
import { useTranslation } from "react-i18next"

export default function PoiContent({ poi, closePopup, loading, nextPoiTimeout, goToNext, removePoi }) {
  const [ isLoading, setIsLoading ] = useState(true)
  // const [ dataUrl, setDataUrl ] = useState()
  const dataUrl = useRef()
  const poiData = useRef()
  const videoRef = useRef()
  const { t } = useTranslation()

  const checkAudioContext = useCallback(() =>{
    const context = new AudioContext()
    console.log('audiocontext: ', context);
  }, [])

  const handlePlayer = useCallback(async() => {
    if (!videoRef.current) return

    try {
      await videoRef.current.play()
    } catch (e) {
      console.error(e);
    }

    checkAudioContext()

    videoRef.current.addEventListener('ended', (event) => {
      // console.log('ended: ', event);
      goToNext()
    })

    // videoRef.current.addEventListener("durationchange", (event) => {
    //   console.log(event);
    // });
    
    // videoRef.current.addEventListener("loadeddata", (event) => {
    //   console.log(event);
    //   videoRef.current.play()
    // });

    // videoRef.current.addEventListener("canplay", (event) => {
    //   console.log('canplay: ', event);
    // })

    // videoRef.current.addEventListener("canplaythrough", (event) => {
    //   console.log(event);
    // })

    return () => {
      videoRef.current.removeEventListener('ended', goToNext())
    }
  }, [checkAudioContext, goToNext])

  const fetchBlob = useCallback(async () => {
    await callApi(
      `/api/v2/routes/${poi.route_uuid}/pois/${poi.id}`,
      'GET',
      '',
      'blob'
    )
    .then(async function (response) {
      // console.log(response);
      const objectUrl = URL.createObjectURL(await response)
      dataUrl.current = objectUrl
      
      if (response.type.includes('video')) {
        setTimeout(async () => {
          await handlePlayer()
        }, 100);
      }
      setIsLoading(false)
    })
    .catch(async function (error) {
      setIsLoading(false)
      // console.error(error);

      const response = await error.json()
      if (submitError(error)) {
        console.error(response);
      }
    })

  }, [poi, dataUrl, setIsLoading, handlePlayer])

  const handleRemovePoi = useCallback(async() => {
    const deleteConfirm = window.confirm(t('confirm'))
    if (!deleteConfirm) return

    PoiStore.removePoi(poi)

    await callApi(
      `/api/v2/routes/${poi.route_uuid}/pois/${poi.id}`,
      'DELETE'
    )
      .then(function(response) {
        console.log('poi removed! ', response);
        removePoi(poi)
      }).catch(async function(error) {
        console.error(error)
      })
  }, [t, poi, removePoi])

  useEffect(() => {
    if (poiData.current !== poi) {
      setIsLoading(true)
      fetchBlob()
      poiData.current = poi
      nextPoiTimeout()
    }
  }, [dataUrl, poi, fetchBlob, handlePlayer, loading, setIsLoading, nextPoiTimeout])

  return (
    <div className='card shadow-lg w-100'>
      <div className="card-header d-flex">
        <b>
          {poi.name}
        </b>

        <button type="button" className="ms-auto btn-close" aria-label="Close" onClick={() => { closePopup() }}></button>
      </div>
      <div className="card-body">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {poi.content_type.includes('image') ? (
              <img src={dataUrl.current} alt={poi.filename} width={'100%'} />
            ) : (
              <video controls width="100%" ref={videoRef}>
                <source src={dataUrl.current} type="video/mp4" />
              </video>
            )}

            <p>{poi.description}</p>

            {poi.content_type.includes('image') && (
              <ProgressBar s={poi.duration} />
            )}

            <small className="d-flex alert alert-danger align-items-center py-2">
              <b>
                Wens je de poi te verwijderen?
              </b>
              <button onClick={() => handleRemovePoi()} className="ms-auto btn btn-sm btn-danger">
                Ja
              </button>
            </small>
          </>
        )}
      </div>
    </div>
  )
}