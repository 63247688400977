import CreateCycleRoute from './CreateCycleRoute';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import InfoBtn from '../Views/InfoBtn';
import { capitalizeFirstLetter, errorToast } from '../Helpers';
import { useTranslation } from 'react-i18next';

export default function NewCycleRoute({ setPageClasses, authType, free, organisation }) {
  const navigate = useNavigate()
  const [ routeType, setRouteType ] = useState(null)
  const { t } = useTranslation('translation');

  const handleRouteType = (type) => {
    setRouteType(type)
  }

  useEffect(() => {
    if (!organisation.active && !free) {
      navigate('/routes')
      errorToast(
        capitalizeFirstLetter(t('organisation inactive license'))
      )
    }

    setPageClasses('Routes')
    if (free && authType === 'Manager') {
      handleRouteType('Free')
    }
  }, [setPageClasses, free, authType, organisation, navigate, t])

  return (
    <>
      <div className='row'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/routes">Routes</Link></li>
            {routeType ? (
              <>
                {routeType === 'Free' ? (
                  <>
                    <li className="breadcrumb-item">
                      <Link
                        to='/routes/free/new'
                      >
                        {capitalizeFirstLetter(t('new route')) }
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{capitalizeFirstLetter(t(routeType.toLowerCase()))}</li>
                  </>
                ) : (
                  <>
                    <li className="breadcrumb-item">
                      <Link
                        to='/routes/new'
                        onClick={() => handleRouteType('')}
                      >
                        {capitalizeFirstLetter(t('new route')) }
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{capitalizeFirstLetter(t(routeType.toLowerCase()))}</li>
                  </>
                )}
              </>
            ) : (
              <li className="breadcrumb-item active" aria-current="page">
                {capitalizeFirstLetter(t('new route')) }
              </li>
            )}
          </ol>
        </nav>
      </div>

      {routeType ? (
        <CreateCycleRoute
          setPageClasses={setPageClasses}
          navigate={navigate}
          routeType={routeType}
          authType={authType}
          organisation={organisation}
          t={t}
        />
      ) : (
        <div className='d-flex'>
          <div className="card mx-auto p-4">
            <div className="card-body">
              {parseInt(organisation.left_envelop) === 0 ?
                <>
                  <b className='d-block'>
                    {capitalizeFirstLetter(t('no credits'))}
                  </b>
                  <Link to='https://shop.memomove.eu/credits/order' className='btn btn-primary btn-sm mt-4'>
                    {capitalizeFirstLetter(t('order credits'))}
                  </Link>
                </>
              :
                <>
                  <h3>
                    {/* You have {organisation.left_envelop} credits */}
                    {capitalizeFirstLetter(t('credits left', { left_envelop: organisation.left_envelop } ))}
                  </h3>
                  <div>
                    {/* You can create a private route with a maximum distance of {organisation.left_envelop}km  */}
                    {capitalizeFirstLetter(t('private route max distance', { left_envelop: organisation.left_envelop }))}
                    <InfoBtn
                      message={t('private route info')}
                    />
                  </div>

                  <div>
                    {/* You can create a shared route with a maximum distance of {organisation.left_envelop_shared}km  */}
                    {capitalizeFirstLetter(t('shared route max distance', { left_envelop: organisation.left_envelop_shared }))}
                    <InfoBtn
                      // message='A shared route is accessable for any organisation which has contributed at least 1 route to the shared community.'
                      message={t('shared route info')}
                    />
                  </div>

                  <div className='d-flex mt-4'>
                    <button className='btn btn-primary btn-sm me-4' onClick={() => handleRouteType('Private')}>
                      {capitalizeFirstLetter(t('create private route'))}
                    </button>
                    <button className='btn btn-primary btn-sm me-4' onClick={() => handleRouteType('Shared')}>
                      {capitalizeFirstLetter(t('create shared route'))}
                    </button>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      )}
    </>
  )
}