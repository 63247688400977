import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../Helpers";

export default function RouteInfo({ data }) {
  const { t } = useTranslation()

  return (
    <>
      <div className="card bg-white position-absolute z-2" style={{ top: 140, left: 20 }}>
        <div className="card-header">
          <h4>
            {data.description }
          </h4>
        </div>

        <div className="card-body d-flex align-items-center justify-content-between">
          <small>
            <b>{capitalizeFirstLetter(t('distance'))}: </b>
          </small>
          <small>{data.distance}m</small>
        </div>
      </div>
    </>
  )
}
