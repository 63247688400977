import { useState, useCallback, useEffect } from "react"

export default function CheckBox({ onChange, label, defaultValue }) {
  const [ checked, setChecked ] = useState(defaultValue)

  const toggleChecked = useCallback(() => {
    setChecked(!checked)
  }, [checked, setChecked])

  useEffect(() => {
    onChange(checked)
  }, [onChange, checked])

  return (
    <>
      <label htmlFor="toggle" className="form-check-label">{label}</label>
      <input id="toggle" type="checkbox" onChange={toggleChecked} checked={checked} style={{ 'width': '8px' }} />
    </>
  )
}
