import React from 'react'
import EmptyCard from '../Views/EmptyCard'
import AccountRow from './AccountRow'
import Loader from '../Views/Loader';
import { capitalizeFirstLetter } from '../Helpers';

class AccountsList extends React.Component {
  render() {
    const { isLoadingAccounts, accounts, showBtn, t } = this.props;
    return (
      isLoadingAccounts ? (
        <Loader />
      ): (
        accounts.length === 0 ? (
          <EmptyCard
            emptyText={capitalizeFirstLetter(t('no accounts found'))}
            createUrl='/accounts/new'
            buttonText='Create a new account'
            showBtn={showBtn}
          />
        ) : (
          <table className='table table-bordered'>
            <thead>
              <tr className=''>
                <th>Id</th>
                <th>{capitalizeFirstLetter(t('name'))}</th>
                <th>Type</th>
                <th>{capitalizeFirstLetter(t('end date'))}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {accounts.map(
                (account) =>
                  <AccountRow
                    key={account.id}
                    index={account.id.toString()}
                    account={account}
                    authType={this.props.authType}
                    t={t}
                  />
              )}
            </tbody>
          </table>
        )
      )
    )
  }
}

export default AccountsList
