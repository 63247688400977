import React from 'react'
import CycleCountries from './CycleCountries'
import RouteCategoryCard from '../Views/RouteCategoryCard'
import Loader from '../Views/Loader'

import {
  Link,
  Routes,
  Route } from 'react-router-dom'
import callApi from '../../utils/ApiCall';
import { capitalizeFirstLetter } from '../Helpers'

class CycleRoutes extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activePage: '',
      isLoadingStats: true,
      freeCycleRoutesStats: {},
      privateCycleRoutesStats: {},
      mySharedCycleRoutesStats: {},
      otherSharedCycleRoutesStats: {}
    }

    this.setActivePage = this.setActivePage.bind(this)
    this.nav_link_classes = this.nav_link_classes.bind(this)
    this.reFetchCycleRoutes = this.reFetchCycleRoutes.bind(this)
  }

  componentDidMount() {
    this.props.setPageClasses('Routes')
    this.fetchCycleRoutesStats()
  }

  componentDidUpdate() {
    this.props.setPageClasses('Routes')

    const {
      freeCycleRoutesStats,
      privateCycleRoutesStats,
      mySharedCycleRoutesStats,
      otherSharedCycleRoutesStats
    } = this.state

    // if (freeCycleRoutesStats === {} || privateCycleRoutesStats === {} || mySharedCycleRoutesStats === {} || otherSharedCycleRoutesStats === {}) {
    if (JSON.stringify(freeCycleRoutesStats) === '{}' || JSON.stringify(privateCycleRoutesStats) === '{}' || JSON.stringify(mySharedCycleRoutesStats) === '{}' || JSON.stringify(otherSharedCycleRoutesStats) === '{}') {
      this.fetchCycleRoutesStats()
    }
  }

  setActivePage(page) {
    this.setState({ activePage: page })
  }

  nav_link_classes(link, sharedAllowed) {
    let default_classes = 'd-flex card px-4 py-2 link-underline link-underline-opacity-0 col'
    const active_class = ' text-bg-primary'
    if (!sharedAllowed && link !== 'Private') {
      default_classes += ' me-4'
    } else if (sharedAllowed && link !== 'Other Shared') {
      default_classes += ' me-4'
    }
    return this.state.activePage === link ? default_classes + active_class : default_classes
  }

  reFetchCycleRoutes() {
    this.setState({ isLoadingStats: true}, async () => {
      await this.fetchCycleRoutesStats()
    })
  }

  async fetchCycleRoutesStats() {
    // console.log('this: ', this);
    if (!this.state.isLoadingStats) return

    let urlBase = ''
    if (this.props.authType === 'Manager') {
      urlBase = `/api/${this.props.organisation.uuid}`
    } else {
      urlBase = '/api'
    }

    const freeCycleRoutesStats = await this.fetchStats(`/api/routes/stats/free`)
    const privateCycleRoutesStats = await this.fetchStats(`${urlBase}/routes/stats/private`)
    let mySharedCycleRoutesStats
    let otherSharedCycleRoutesStats
    if (this.props.organisation.community === 'Sh') {
      mySharedCycleRoutesStats = await this.fetchStats(`${urlBase}/routes/stats/my/shared`)
      otherSharedCycleRoutesStats = await this.fetchStats(`${urlBase}/routes/stats/other/shared`)
    } else {
      mySharedCycleRoutesStats = []
      otherSharedCycleRoutesStats= []
    }

    this.setState({
      freeCycleRoutesStats,
      privateCycleRoutesStats,
      mySharedCycleRoutesStats,
      otherSharedCycleRoutesStats
    }, () => {
      this.setState({ isLoadingStats: false })
    })
  }

  async fetchStats(url) {
    return await callApi(url)
      .then(function (response) {
        // console.log('response: ', response);
        return { countries_count: response.data.countries_count, route_count: response.data.route_count }
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse);
      });
  }

  render () {
    const {
      isLoadingStats,
      freeCycleRoutesStats,
      privateCycleRoutesStats,
      mySharedCycleRoutesStats,
      otherSharedCycleRoutesStats
    } = this.state

    const { organisation, authType, t } = this.props
    const sharedAllowed = organisation.community === 'Sh'

    return (
      <>
        <div className='d-flex align-items-center'>
          <h1>
            Routes
          </h1>
          <div className='ms-auto'>
            {authType === 'Manager' && (
              <Link to='/routes/free/new' className='btn btn-primary me-2'>
                {capitalizeFirstLetter(t('add free route'))}
              </Link>
            )}
            <Link
              to='/routes/new'
              className={`btn btn-primary${organisation.active ? '' : ' disabled'}`}
            >
              {capitalizeFirstLetter(t('add route'))}
            </Link>
          </div>
        </div>
        
        {isLoadingStats ? (
          <Loader />
        ) : (
          <>
            <div className="d-flex">
              <RouteCategoryCard
                name='Free'
                url='/routes/free'
                countries_count={freeCycleRoutesStats.countries_count}
                route_count={freeCycleRoutesStats.route_count}
                nav_link_classes={this.nav_link_classes}
                sharedAllowed={sharedAllowed}
                t={t}
              />
              <RouteCategoryCard
                name='Private'
                url='/routes/private'
                countries_count={privateCycleRoutesStats.countries_count}
                route_count={privateCycleRoutesStats.route_count}
                nav_link_classes={this.nav_link_classes}
                sharedAllowed={sharedAllowed}
                t={t}
              />
              {sharedAllowed && (
                <>
                  <RouteCategoryCard
                    name='My Shared'
                    url='/routes/my/shared'
                    countries_count={mySharedCycleRoutesStats.countries_count}
                    route_count={mySharedCycleRoutesStats.route_count}
                    nav_link_classes={this.nav_link_classes}
                    sharedAllowed={sharedAllowed}
                    t={t}
                  />
                  <RouteCategoryCard
                    name='Other Shared'
                    url='/routes/other/shared'
                    countries_count={otherSharedCycleRoutesStats.countries_count}
                    route_count={otherSharedCycleRoutesStats.route_count}
                    nav_link_classes={this.nav_link_classes}
                    sharedAllowed={sharedAllowed}
                    t={t}
                  />
                </>
              )}
            </div>

            <Routes>
              <Route
                path='/free/*'
                element={
                  <CycleCountries
                    type='Free'
                    urlSuffix='free'
                    setActivePage={this.setActivePage}
                    authType={authType}
                    location={this.props.location}
                    reFetchCycleRoutes={this.reFetchCycleRoutes}
                    t={t}
                  />
                }
              />
              <Route
                path='/private/*'
                element={
                  <CycleCountries
                    type='Private'
                    urlSuffix='private'
                    setActivePage={this.setActivePage}
                    authType={authType}
                    orgUuid={organisation.uuid}
                    location={this.props.location}
                    reFetchCycleRoutes={this.reFetchCycleRoutes}
                    t={t}
                  />
                }
              />
              <Route
                path='/my/shared/*'
                element={
                  <CycleCountries
                    type='My Shared'
                    urlSuffix='my/shared'
                    setActivePage={this.setActivePage}
                    authType={authType}
                    orgUuid={organisation.uuid}
                    location={this.props.location}
                    reFetchCycleRoutes={this.reFetchCycleRoutes}
                    t={t}
                  />
                }
              />
              <Route
                path='/other/shared/*'
                element={
                  <CycleCountries
                    type='Other Shared'
                    urlSuffix='other/shared'
                    setActivePage={this.setActivePage}
                    authType={authType}
                    orgUuid={organisation.uuid}
                    location={this.props.location}
                    reFetchCycleRoutes={this.reFetchCycleRoutes}
                    t={t}
                  />
                }
              />
            </Routes>
          </>
        )}
      </>
    )
  }
}

export default CycleRoutes
