import React from 'react'
import callApi from '../../utils/ApiCall';
import AccountsList from './AccountsList'
import { Link } from 'react-router-dom';
import { prepareAccounts, capitalizeFirstLetter } from '../Helpers'

class ClosedAccounts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoadingAccounts: true,
      accounts: []
    }
  }

  async componentDidMount() {
    this.props.setActiveNav('Accounts')
    await this.fetchClosedAccounts()
  }

  async fetchClosedAccounts() {
    const that = this
    let url
    if (this.props.authType === 'Admin') {
      url = `/accounts/closed`
    } else {
      const uuid = this.props.orgUuid
      url = `/organisations/${uuid}/accounts/closed`
    }
    await callApi(url)
      .then(function (response) {
        // console.log(response);
        if (!response.data.accounts) {
          that.setState({ isLoadingAccounts: false })
          return
        }

        const accounts = prepareAccounts(response.data.accounts)
        that.setState({ accounts }, () => {
          that.setState({ isLoadingAccounts: false })
        })
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  render () {
    const { organisation, t } = this.props
    const { accounts, isLoadingAccounts } = this.state

    return (
      <>
        <h1>
          Accounts
        </h1>
        
        <div className='d-flex align-items-center'>
          <ul className="nav nav-pills">
            <li role="presentation" className="nav-item">
              <Link to="/accounts" className="nav-link">Open</Link>
            </li>
            <li role="presentation" className="nav-item">
              <Link to="/accounts/closed" className='nav-link active'>
                {capitalizeFirstLetter(t('closed'))}
              </Link>
            </li>
          </ul>

          {organisation.active && (
            <Link to='/accounts/new' className='ms-auto btn btn-primary'>
              {capitalizeFirstLetter(t('add new'))}
            </Link>
          )}
        </div>

        <br />

        <AccountsList
          isLoadingAccounts={isLoadingAccounts}
          accounts={accounts}
          showBtn={false}
          t={t}
        />
      </>
    )
  }
}

export default ClosedAccounts
