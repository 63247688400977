import TextInput from '../Form/TextInput'
import callApi from '../../utils/ApiCall'
import { Link } from 'react-router-dom'
import { capitalizeFirstLetter } from '../Helpers'
import CreateStore from '../../stores/CreateStore'
import { useEffect, useCallback, useState, useRef } from 'react'
import { submitSuccess, submitError, successToast, errorToast } from '../Helpers'
import { useNavigate } from "react-router-dom";

export default function RoutePointList({ t, authType, type, organisationUuid }) {
  const [ routePoints, setRoutePoints ] = useState([])
  const [ totalDistance, setTotalDistance ] = useState(0)
  const [ description, setDescription ] = useState('')
  const [ drivingType, setDrivingType ] = useState('driving')
  const [ formErrorFields, setFormErrorFields ] = useState([])
  const [ savingRoute, setSavingRoute ] = useState(false)
  const routeDataEl = useRef()
  // const routePointsHeightSet = useRef(false)
  const navigate = useNavigate()

  const removeDistanceError = useCallback(() => {
    if (formErrorFields.length === 0) return 

    const errorField = formErrorFields.filter(error => error.field !== 'distance')
    setFormErrorFields([...errorField])
  }, [formErrorFields, setFormErrorFields])

  const handleDescription = useCallback((newValue) => {
    setDescription(newValue)
    if (newValue.length >= 20 && formErrorFields.length > 0) {
      const descriptionError = formErrorFields.find(error => error.field === 'description')
      if (!descriptionError) return
      
      setFormErrorFields([])
    }
  }, [formErrorFields, setFormErrorFields])

  const checkDistanceAllowed = useCallback(async(distance) => {
    let url
    if (authType === 'Manager') {
      url = `/api/${organisationUuid}/routes/create/allowed/${distance}`
    } else {
      url = `/api/routes/create/allowed/${distance}`
    }

    if (type === 'Shared') {
      url += '/shared'
    }
    
    await callApi(url)
      .then(function (response) {
        // console.log(response)
        if (!response.data.distance_allowed) {
          const distanceAllowedError = {
            field: 'distance',
            message: t('distance error')
            // 'Not enough credits left to create the route. Please shorting your route.'
          }
          setFormErrorFields([...formErrorFields, distanceAllowedError])
        } else {
          removeDistanceError()
        }
      })
      .catch(function (error) {
        console.error(error)
      });
  }, [type, authType, organisationUuid, t, formErrorFields, setFormErrorFields, removeDistanceError])

  // const setRoutePointsHeight = useCallback(() => {
  //   const createFormHeight = document.getElementById('createForm').offsetHeight
  //   console.log('createFormHeight: ', createFormHeight);

  //   console.log('routeDataEl: ', routeDataEl.current);
  //   document.getElementById('routePoints').style['height'] = `${routeDataEl.current - createFormHeight}px`
  // }, [])

  const handleRoutePoints = useCallback(() => {
    const updatedRoutePoints = CreateStore.getRoutePoints()
    // console.log('handleRoutePoints: ', updatedRoutePoints);
    
    setRoutePoints([...updatedRoutePoints])

    setTimeout(() => {
      document.getElementById('routePoints').children[document.getElementById('routePoints').children.length-1].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    }, 100);

    if (updatedRoutePoints.length < 2) removeDistanceError()
  }, [setRoutePoints, removeDistanceError])

  const handleDistance = useCallback(() => {
    const distance = CreateStore.getRouteDistance()
    setTotalDistance(distance)
    if (type !== 'Free') {
      checkDistanceAllowed(distance)
    }
  }, [type, checkDistanceAllowed, setTotalDistance])

  // componentDidMount() {
  //   const { description } = this.props.formData
  //   if (this.state.description === '' && description !== '') {
  //     this.setState({ description })
  //   }

  // componentDidUpdate() {
  //   // scrollPointsList to bottom...
  //   let element = document.getElementById("routePointsParent");
  //   if (element) {
  //     element.scrollTop = element.scrollHeight;
  //   }
  // }

  const pointLetter = useCallback((id) => {
    return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(id);
  }, [])

  const errorMessage = useCallback((fieldName)  => {
    // console.log('formErrorFields: ', formErrorFields);
    if (formErrorFields.length === 0) return null
    // console.log(formErrorFields.find(error => error.field === fieldName));

    return formErrorFields.find(error => error.field === fieldName)
  }, [formErrorFields])

  const handleDrivingType = useCallback((drivingType) => {
    CreateStore.setDrivingType(drivingType)
    setDrivingType(drivingType)
  }, [])

  const removeRoutePoint = useCallback((point) => {
    // console.log('removeRoutePoints: ', point);
    CreateStore.removeRoutePoint(point)
  }, [])

  const routeData = useCallback(() => {
    const directionResult = CreateStore.getDirectionsResult()

    const routes = directionResult.routes[0]
    const legs = routes.legs
    const steps = legs.map((leg) => {
      return leg.steps.map(step => {
        return step.lat_lngs.map(lat_lngs => [lat_lngs.lat(), lat_lngs.lng()])
      })
    })

    const request = directionResult.request
    const origin_point = request.origin.location.toJSON()
    const destination_point = request.destination.location.toJSON()

    // console.log('routePoints: ', routePoints);

    return {
      community: type,
      description,
      routepoints: routePoints,
      start_point: [origin_point.lat, origin_point.lng],
      end_point: [destination_point.lat, destination_point.lng],
      waypoints: request['waypoints'],
      distance: totalDistance,
      travelmode: drivingType,
      bounds: routes['bounds'],
      // overview_polyline: this.directionResult['overview_polyline'],
      overview_polyline: directionResult.overview_polyline,
      overview_path: routes['overview_path'],
      summary: routes['summary'],
      warnings: routes['warnings'],
      waypoint_order: routes['waypoint_order'],
      steps: steps.flat(2),
      country_code: CreateStore.getCountryCode(),
      directions: directionResult
    }
  }, [description, totalDistance, type, routePoints, drivingType])

  const checkRouteStatus = useCallback(async (routeId) => {
    let url
    if (authType === 'Manager' && type !== 'Free') {
      url = `/api/${organisationUuid}/routes/${routeId}/status`
    } else if (authType === 'Manager') {
      url = `/api/routes/free/${routeId}/status`
    } else {
      url = `/api/routes/${routeId}/status`
    }
    return await callApi(url)
      .then(function (response) {
        // console.log(response)
        return response.data
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse)
      });
  }, [authType, type, organisationUuid])

  const checkRouteInterval = useCallback((routeId) => {
    let looper = setInterval(async () => {
      let output = await checkRouteStatus(routeId)
      // console.log(output);
      if (output.id) {
        clearInterval(looper);
        CreateStore.clearRoutePoints()
        successToast(
          t('first run')
        )
        navigate(`/routes/${output.id}/create`)
      }
    }, 1500);
  }, [checkRouteStatus, t, navigate])

  const saveRoute = useCallback((e) => {
    e.preventDefault()
    
    if (formErrorFields.length > 0 || routePoints.length < 2) return
    
    const data = routeData()
    if (data === undefined) return

    // routeData['description'] = description
    data['community'] = type

    let url
    if (authType === 'Manager' && type !== 'Free') {
      url = `/api/${organisationUuid}/routes/create`
    } else if (authType === 'Manager') {
      url = `/api/routes/free/create`
    } else {
      url = `/api/routes/create`
    }

    // CreateStore.setSavingRoute(true)
    setSavingRoute(true)

    callApi(
      url,
      'POST',
      data
    )
    .then(function (response) {
      // console.log(response)
      if (submitSuccess(response)) {
        successToast(
          t('route map created')
        )

        checkRouteInterval(response.data.route.id)
      }
    })
    .catch(async function (error) {
      const response = await error.json()
      if (submitError(error)) {
        console.log(response);
        errorToast(t(response.message))

        setFormErrorFields(response.fields)
        setSavingRoute(false)
      }
    })
  }, [formErrorFields, routePoints, routeData, authType, t, organisationUuid, type, setFormErrorFields, setSavingRoute, checkRouteInterval])

  useEffect(() => {
    if (!routeDataEl.current) {
      routeDataEl.current = document.getElementById('routeData').offsetHeight
    }

    CreateStore.setRoutePoints(routePoints)
    CreateStore.addRoutePointsChangeListener(handleRoutePoints)
    CreateStore.addRouteDistanceChangeListener(handleDistance)
    return () => {
      CreateStore.removeRoutePointsChangeListener(handleRoutePoints)
      CreateStore.removeRouteDistanceChangeListener(handleDistance)
      // CreateStore.clearRoutePoints()
    }
  }, [handleRoutePoints, handleDistance, routePoints])

  return (
    <div className='mb-4 h-100 d-flex flex-column justify-content-between'>
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className=''>
          {t('points')}
        </h3>
        <small className="text-danger-emphasis">{routePoints.length}/10</small>
      </div>
      <div id="routeData" className='d-flex flex-column h-100'>
        <div className='overflow-y-scroll mb-2 flex-grow-1' id='routePoints' style={{ height: 'calc(80vh - 285px)' }}>
          {routePoints.length <= 1 && (
            <div className="card mb-2">
              <small className="card body p-2 text-bg-warning">
                {routePoints.length === 1 ? (
                  t('choose end point')
                ) : (
                  t('choose start and end point')
                )}
                <br/>
                {t('max points')}
              </small>
            </div>
          )}
          {routePoints.map((point, i) => {
            return (
              <div key={i} className='card fs-6 mb-2'>
                <div className='card-body d-flex align-items-start'>
                  <b>{pointLetter(i)}</b>
                  <small className='ms-2 me-auto'>
                    {point.name}
                  </small>
                  <button
                    onClick={() => removeRoutePoint(point)}
                    className='btn btn-danger btn-sm ms-2'
                    disabled={savingRoute}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            )
          })}
        </div>
        <div id="createForm" className=''>
          {routePoints.length > 1 && (
            <>
              <small>
                <b>{capitalizeFirstLetter(t('distance'))}:</b> {totalDistance}m
              </small>

              <div className='d-flex justify-content-between align-items-center'>
                <small>
                  <b>Driving type:</b>
                </small>
                <div className='btn-group'>
                  <label
                    htmlFor='drivingType_driving'
                    className={`btn ${drivingType === 'driving' ? 'btn-primary' : 'btn-outline-primary'}`}
                  >
                    <i className="fas fa-car"></i>
                    <input
                      id="drivingType_driving"
                      type='radio'
                      name='drivingType'
                      value='driving'
                      onChange={() => handleDrivingType('driving')} defaultChecked className='d-none'
                      readOnly={savingRoute}
                    />
                  </label>
                  <label
                    htmlFor='drivingType_bicycle'
                    className={`btn ${drivingType === 'bicycling' ? 'btn-primary' : 'btn-outline-primary'}`}
                  >
                    <i className="fas fa-bicycle"></i>
                    {/* <small>(in Beta)</small> */}
                    <input
                      id="drivingType_bicycle"
                      type='radio'
                      name='drivingType'
                      value='bicycling'
                      onChange={() => handleDrivingType('bicycling')} className='d-none'
                      readOnly={savingRoute}
                    />
                  </label>
                </div>
              </div>
            </>
          )}

          {errorMessage('distance') && (
            <>
              <small className='d-block alert alert-danger mt-2'>
                {errorMessage('distance').message}
                <div className='d-flex align-items-center mt-2'>
                  <div className='mx-auto'>
                    {t('or')}
                    <Link to={`${process.env.REACT_APP_GO_URL}/order/envelop`} className='btn btn-outline-primary btn-sm ms-2'>
                      {t('order credits')}
                    </Link>
                  </div>
                </div>
              </small>
            </>
          )}

          <form
            onSubmit={
              (e) => saveRoute(e)
            }
          >
            <TextInput
              title={capitalizeFirstLetter(t('community'))}
              name={'community'}
              value={type}
              type={'hidden'}
            />

            <TextInput
              title={capitalizeFirstLetter(t('description'))}
              helper={capitalizeFirstLetter(t('min 20 chars'))}
              name={'description'}
              value={description}
              handleChange={handleDescription}
              readonly={savingRoute}
              error={errorMessage('description')}
              focus={true}
            />

            {(routePoints.length >= 2 && !errorMessage('distance') && !savingRoute) && (
              <input
                type="submit"
                className='btn btn-primary'
                value={capitalizeFirstLetter(t('create route')) }
              />
            )}

            {savingRoute && (
              <button className='btn btn-primary' disabled>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="ms-2" role="status">{capitalizeFirstLetter(t('saving'))}</span>
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
