import { Link } from 'react-router-dom';
import "/node_modules/flag-icons/css/flag-icons.min.css";

export default function RouteCountryCard({ name, code, url, route_count, nav_link_classes }) {
  return (
    <Link to={url} className={`${nav_link_classes(name)} me-2`}>
      <b>{name}</b>
      {/* <img src={'/static/img/flags/svg/' + name.toLowerCase() + '.svg'} alt={name.toLowerCase() + ' flag'} className='w-25 mx-auto' /> */}
      <span className={`fi fi-${code.toLowerCase()}`} style={{ width: '2rem', height: '2rem' }}></span>
      <small>
        Routes: {route_count}
      </small>
    </Link>
  )
}
