export default function Loader({ textColor = 'primary', style = {} }) {
  const colorClass = textColor === 'primary' ? 'text-primary' : ''

  return (
    <div className='w-100 my-8 d-flex align-items-center justify-content-center'>
      <div className={`spinner-border ${colorClass}`} role="status" style={style}>
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}