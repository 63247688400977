import MemoStore from "../../../stores/MemoStore";
import { useEffect, useState, useCallback } from "react";

export default function NoPano() {
  const [ show, setShow ] = useState(false)

  const handleStatus = useCallback(() => {
    const status = MemoStore.getPanoStatus()
    if (!status && show) {
      setShow(false)
      return
    }
    if (!status) return

    if (status !== 'OK') {
      console.error('Pano status: ', status);
      setShow(true)
    } else if (show) {
      setShow(false)
    }
  }, [show, setShow])
  

  useEffect(() => {
    MemoStore.addPanoStatusChangeListener(handleStatus)

    return () => {
      MemoStore.removePanoStatusChangeListener(handleStatus)
    }
  }, [handleStatus])
  
  return (
    <>
      {show && (
        <div className="position-absolute bg-white rounded-3 p-2 z-3" style={{ left: '50%', top: '50%' }}>
          No valid pano found
        </div>
      )}
    </>
  )
}