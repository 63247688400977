import PropTypes from 'prop-types'
import _ from 'lodash'
import { useCallback } from 'react'

export default function TextArea({ handleChange, title, name, value = '', readonly, focus, error, helper}) {
  TextArea.propTypes = {
    handleChange: PropTypes.func,
    title: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    readonly: PropTypes.bool,
    focus: PropTypes.bool,
    error: PropTypes.object,
    helper: PropTypes.string
  }
  
  TextArea.defaultProps = {
    handleChange: PropTypes.func,
    title: '',
    name: '',
    value: '',
    readonly: false,
    focus: false,
    error: {},
    helper: ''
  }

  // console.log(error);
  let inputClassNames = 'form-control'
  if (readonly) {
    inputClassNames += ' text-bg-light'
  } else if (!_.isEmpty(error)) {
    inputClassNames += ' border-danger'
  }
  const labelClassNames = `d-flex ${_.isEmpty(error) ? '' : 'text-danger'}`

  const onChange = useCallback((e) => {
    const value = e.target.value
    handleChange(value)
  }, [handleChange])

  return (
    <>
      <div className='my-2'>
        <label htmlFor={name} className={labelClassNames}>
          {title}
          {helper && (
            <small className='ms-auto text-danger-emphasis'>{helper}</small>
          )}
        </label>
        <div className="from-group">
          <textarea value={value.toString()} id={name} name={name} onChange={(e) => onChange(e)} className={inputClassNames} readOnly={readonly} autoFocus={focus}></textarea>
          {!_.isEmpty(error) && (
            <small className='text-danger'>
              {error.message}
            </small>
          )}
        </div>
      </div>
    </>
  )
}
