import { useState, useEffect, useRef } from "react"

export default function InfoBtn({ message }) {
  const questionMarkRef = useRef(null)
  const [showMessage, setShowMessage] = useState(false)

  const handlePopup = () => {
    setShowMessage(true)
  }

  const popupStyle = { width: '300px', left: '30px', top: '-20px' }

  useEffect(() => {
    function hideMessage(e) {
      if (e.target !== questionMarkRef.current) {
        setShowMessage(false)
      }
    }
    document.body.addEventListener('click', hideMessage);
    return () => {
      document.body.removeEventListener('click', hideMessage);
    };
  }, [setShowMessage]);

  return (
    <button onClick={handlePopup} className='position-relative border-0 bg-body ms-2'>
      <i className="far fa-question-circle" ref={questionMarkRef}></i>
      {showMessage && (
        <div style={popupStyle} className='position-absolute z-1 bg-secondary-subtle border-1 rounded-3 px-4 py-2'>
          <small>{message}</small>
        </div>
      )}
    </button>
  )
}
