import {
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'

import Dashboard from './Dashboard'
import Accounts from './Accounts/Accounts'
import ClosedAccounts from './Accounts/ClosedAccounts'
import NewAccount from './Accounts/NewAccount'
import EditAccount from './Accounts/EditAccount'
import CycleRoutes from './CycleRoutes/CycleRoutes'
import NewCycleRoute from './CycleRoutes/NewCycleRoute'
import EditCycleRoute from './CycleRoutes/EditCycleRoute'
// import RoutePlayer from './Player/RoutePlayer'
import RoutePlayer from './v2/Player/RoutePlayer'
import RoutePoiPlayer from './v2/POI/RoutePoiPlayer'
import Creator from './v2/Creator/Creator'
import NotFound from './Views/NotFound'
import Header from './Admin/Header'
import { useTranslation } from 'react-i18next';

export default function AdminPages({ user, organisation, updateOrganisation, nav_link_classes, handleLogout, containerClasses, setContainerClasses, headerClasses, setPageClasses }) {  
  const location = useLocation()
  const pathIsPlayOrCreate = location.pathname.endsWith('play') || location.pathname.endsWith('create')
  const { t } = useTranslation('translation');

  const AUTH_TYPE = 'Admin'

  return (
    <>
      <Header
        headerClasses={headerClasses}
        nav_link_classes={nav_link_classes}
        organisation={organisation}
        user={user}
        handleLogout={handleLogout}
      />
      <div className={containerClasses}>
        {!organisation.active && !pathIsPlayOrCreate && (
          <small className='alert bg-warning p-1'>
            {/* Your order payment hasn't been received yet. You can only use Memomove with limited access. */}
            {t('current_organisation_inactive')}
          </small>
        )}

        <Routes>
          <Route index path="/" element={
            <Dashboard
              // setActiveNav={''}
              setActiveNav={setPageClasses}
              organisationName={organisation.name}
              t={t}
            />
          }
          />
          <Route path="accounts" element={
            <Accounts
              setActiveNav={setPageClasses}
              authType={AUTH_TYPE}
              organisation={organisation}
              t={t}
            />
          }
          />
          <Route path="accounts/closed" element={
            <ClosedAccounts
              setActiveNav={setPageClasses}
              authType={AUTH_TYPE}
              organisation={organisation}
              t={t}
            />
          }
          />
          <Route path="accounts/new" element={
            <NewAccount
              navPage
              setActiveNav={setPageClasses}
              authType={AUTH_TYPE}
              organisation={organisation}
            />
          }
          />
          <Route path="accounts/:uuid/edit" id="uuid" element={
            <EditAccount
              setActiveNav={setPageClasses}
              organisation={organisation}
              authType={AUTH_TYPE}
            />
          }
          />
          <Route
            path='routes/new'
            element={
              <NewCycleRoute
                setPageClasses={setPageClasses}
                organisation={organisation}
              />
            }
          />
          <Route
            path='routes/:id/play'
            element={
              <RoutePlayer
                authType={AUTH_TYPE}
                setActiveNav={setPageClasses}
              />
            }
          />
          <Route
            path='routes/:id/poi'
            element={
              <RoutePoiPlayer
                setActiveNav={setPageClasses}
                authType={AUTH_TYPE}
                orgUuid={organisation.uuid}
                t={t}
              />
            }
          />
          <Route
            path='routes/:id/create'
            element={
              <Creator
                organisation={organisation}
                updateOrganisation={updateOrganisation}
                setContainerClasses={setContainerClasses}
                setActiveNav={setPageClasses}
              />
            }
          />
          <Route
            path='routes/:id/edit'
            element={
              <EditCycleRoute
                setPageClasses={setPageClasses}
                setContainerClasses={setContainerClasses}
                authType={AUTH_TYPE}
                organisation={organisation}
              />
            }
          />
          <Route path="routes/*" element={
            <CycleRoutes
              setPageClasses={setPageClasses}
              organisation={organisation}
              authType={AUTH_TYPE}
              t={t}
            />
          }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  )
}