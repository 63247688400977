
import { useState, useCallback, useEffect } from 'react';
import AccountForm from './AccountForm';
import { submitSuccess, submitError, successToast, errorToast, pageLoader, licenseType, capitalizeFirstLetter } from '../Helpers'
import { useNavigate, Link } from "react-router-dom";
import callApi from '../../utils/ApiCall';
import { useTranslation } from 'react-i18next';

export default function NewAccount({ navPage, setActiveNav, authType, organisation }) {
  // const [account] = useState(null);
  const [checkCanAddUser, setCheckCanAddUser] = useState(true)
  const [canAddUser, setCanAddUser] = useState(false)
  const [formErrorFields, setFormErrorFields] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation('translation');

  const errorMessage = t('active accounts limit', { organisation_name: organisation.name, license_name: licenseType(organisation.license_type), allowed_accounts: organisation.allowed_accounts })
    // `U behoort tot de organisatie ${organisation.name} en het afgesloten licentietype is ${organisation.license_name}. ` +
    // `Dit betekent dat uw organisatie recht heeft op ${organisation.allowed_accounts} actieve gebruikers, en dat aantal is bereikt.`
    // `You are a member of the organisation ${organisation.name} and the license type is ${licenseType(organisation.license_type)}. ` +
    // `This means your organisation is allowed to have ${organisation.allowed_accounts} active users and that number has been reached.`

  useEffect(() => {
      if (navPage !== 'Accounts') {
        setActiveNav('Accounts')
      }

      if (!organisation.active) {
        navigate('/accounts')
        errorToast(capitalizeFirstLetter(t('no active license')))
      }
    }, [navPage, setActiveNav, organisation, navigate, t]
  )
  
  useEffect(() => {
    let url
    if (authType === 'Admin') {
      url = `/accounts/add/check`
    } else {
      url = `/organisations/${organisation.uuid}/accounts/add/check`
    }
    callApi(url)
      .then(function (response) {
        setCheckCanAddUser(false)
        setCanAddUser(response.data.success)
      })
      .catch(function (error) {
        console.error(error)
      });
  }, [authType, organisation, setCheckCanAddUser, setCanAddUser])

  const handleSubmit = useCallback((accountData) => {
    let url
    if (authType === 'Admin') {
      url = `/accounts`
    } else {
      url = `/organisations/${organisation.uuid}/accounts`
    }
    callApi(url,
      'POST',
      accountData
    )
      .then(function (response) {
        // console.log(response)
        if (submitSuccess(response)) {
          successToast(t('Account created'))
          navigate("/accounts")
        }
      })
      .catch(async function (error) {
        // console.log(error)
        if (submitError(error)) {
          const response = await error.json()
          errorToast(response.message)
          setFormErrorFields(response.fields)
        }
      });
  }, [navigate, authType, organisation, t])

  return (
    <div className='row'>
      <h1>{t('new account')}</h1>

      {checkCanAddUser ? (
        pageLoader
      ) : (
        canAddUser ? (
          <AccountForm
            // formData={account}
            organisation={organisation}
            type="New"
            formErrorFields={formErrorFields}
            submitLabel={capitalizeFirstLetter(t('save'))}
            handleSubmit={handleSubmit}
            t={t}
          />
        ) : (
          <>
            <div className='alert alert-danger'>
              {errorMessage}
            </div>
            <div className='d-flex'>
              <Link to='/accounts' className='btn btn-primary mx-auto'>
                {capitalizeFirstLetter(t('back to accounts'))}
              </Link>
            </div>
          </>
        )
      )}
      
    </div>
  )
}
