import { useEffect, useCallback, useState, useRef } from "react"
import { submitSuccess, successToast, submitError, errorToast, capitalizeFirstLetter } from "../../Helpers"
import callApi from "../../../utils/ApiCall"
import { useTranslation } from "react-i18next"
import TextInput from "../../Form/TextInput"
import TextArea from "../../Form/TextArea"
import PoiStore from "../../../stores/PoiStore"
import MemoStore from "../../../stores/MemoStore"
import DirectionsStore from "../../../stores/DirectionsStore"

export default function NewPoi({ authType, orgUuid, id, moveTwoStepsBack }) {
  const [ showForm, setShowForm ] = useState(false)
  const [ savingPoi, setSavingPoi ] = useState(false)
  const [ poiData, setPoiData ] = useState({
    name: '',
    description: '',
    file: undefined,
    duration: 5,
    errors: []
  })
  const [ showDuration, setShowDuration ] = useState(false)
  const currentStep = useRef()
  const { t } = useTranslation()

  const resetForm = useCallback(() => {
    const defaultState = {
      name: '',
      description: '',
      file: '',
      duration: 5,
      errors: []
    }
    const fileInput = document.getElementById('file')
    fileInput.value = ''
    setPoiData(defaultState)
  }, [])

  const closeForm = useCallback((e) => {
    if (e) e.preventDefault()

    PoiStore.setShowForm(false)
    MemoStore.setPaused(false)
    setShowForm(false)
    resetForm()
  }, [resetForm])

  const toggleDuration = useCallback((file) => {
    if (!file) return
    if (file.type.includes('image')) {
      setShowDuration(true)
    } else if (showDuration) {
      setShowDuration(false)
    }
  }, [setShowDuration, showDuration])

  const setFormErrorFields = useCallback((errors) => {
    const updateData = { ...poiData, errors}
    setPoiData(updateData)
  }, [setPoiData, poiData])

  const savePoi = useCallback((e) => {
    e.preventDefault()

    // console.log('authType: ', authType);

    let url
    if (authType === 'Manager') {
      url = `/api/v2/routes/${id}/poi/create`
    } else {
      url = `/api/v2/${orgUuid}/routes/${id}/poi/create`
    }

    const data = new FormData()
    for (const name in poiData) {
      if (name !== 'errors') data.append(name, poiData[name])
    }
    data.append('location', JSON.stringify(currentStep.current.location))
    data.append('step_uuid', currentStep.current.uuid)

    setSavingPoi(true)

    callApi(
      url,
      'POST',
      data,
      'multipart/form-data'
    )
    .then(function (response) {
      // console.log(response)
      if (submitSuccess(response)) {
        successToast(
          t('poi created')
        )
        setSavingPoi(false)
        
        // !! Move Step backwards...
        resetForm()
        setShowForm(false)
        moveTwoStepsBack(currentStep.current)
      }
    })
    .catch(async function (error) {
      const response = await error.json()
      if (submitError(error)) {
        console.log(response);
        errorToast(response.message)

        setFormErrorFields(response.fields)
        setSavingPoi(false)
      }
    })
  }, [authType, t, orgUuid, id, poiData, currentStep, setSavingPoi, setFormErrorFields, setShowForm, resetForm, moveTwoStepsBack])

  const toggleForm = useCallback(() => {
    const showForm = PoiStore.getShowForm()
    setShowForm(showForm)
  }, [])

  const setCurrentStep = useCallback(() => {
    currentStep.current = DirectionsStore.getCurrentStep()
    // console.log('setcurrentStep: ', currentStep.current);
  }, [currentStep])

  const validateInputLength = useCallback((value, fieldName) => {
    const minLength = fieldName === 'name' ? 8 : 24
    const errors = [...poiData.errors]
    const fieldError = errors.find(error => error.field === fieldName)

    if (value.length < minLength && !fieldError) {
      errors.push(
        {
          field: fieldName,
          message: t('min length', { min: minLength })
        }
      )
    }

    if (value.length >= minLength && fieldError) {
      return errors.filter(error => error.field !== fieldName)
    }

    return errors
  }, [t, poiData])

  const handleChange = useCallback((value, fieldName) => {
    if (['name', 'description'].includes(fieldName)) {
      const updatedErrors = validateInputLength(value, fieldName)
      // console.log('updatedErrors: ', updatedErrors);
      const updatedPoiData = {
        ...poiData,
        errors: updatedErrors
      }

      updatedPoiData[fieldName] = value
      setPoiData(updatedPoiData)
    }

    if (fieldName === 'file') {
      let updatedErrors

      // Validate filesize
      if (value.size / 1000 / 1000 > 15) {
        updatedErrors = [
          ...poiData.errors,
          {
            field: 'file',
            message : 'entity_to_large'
          }
        ]
      } else {
        updatedErrors = [...poiData.errors.filter(error => error.field !== 'file')]
      }


      setPoiData({
        ...poiData,
        file: value,
        errors: updatedErrors
      })

      // console.log('updatedPoiData: ', value);

      toggleDuration(value)
    }

    if (fieldName === 'duration') {
      setPoiData({
        ...poiData,
        duration: value
      })
    }
    
  }, [setPoiData, poiData, validateInputLength, toggleDuration])

  // const clearFile = useCallback((e) => {
  //   e.preventDefault()
  //   const updatedPoiData = { ...poiData, file: '' }
  //   const fileInput = document.getElementById('file')
  //   fileInput.value = ''
  //   setPoiData(updatedPoiData)
  // }, [poiData, setPoiData])
  
  useEffect(() => {
    PoiStore.addToggleFormListener(toggleForm)

    DirectionsStore.addCurrentStepChangeListener(setCurrentStep)
    return () => {
      PoiStore.removeToggleFormListener(toggleForm)
      DirectionsStore.removeCurrentStepChangeListener(setCurrentStep)
    }
  }, [toggleForm, setCurrentStep, poiData])

  return (
    <>
      <div
        className={`${showForm ? '' : 'd-none '}position-absolute bg-opacity-50 bg-dark w-100 h-100 z-3`}
      >
        <div className="w-100 d-flex justify-content-around h-100 align-items-center">
          <div className='card shadow-lg w-50 h-75'>
            <div className="card-header d-flex justify-content-between">
              <b>New POI</b>

              <button type="button" className="ms-auto btn-close" aria-label="Close" onClick={(e) => { closeForm(e) }}></button>
            </div>

            <form
              onSubmit={
                (e) => savePoi(e)
              }
              encType="multipart/form-data"
              className="h-100 d-flex flex-column"
            >
              <div className="card-body">
                <TextInput
                  handleChange={(value) => handleChange(value, 'name')}
                  title={capitalizeFirstLetter(t('name'))}
                  name="name"
                  type="text"
                  error={poiData.errors.find(error => error.field === 'name')}
                  value={poiData.name}
                  readonly={savingPoi}
                  focus={true}
                />

                <TextArea
                  handleChange={(value) => handleChange(value, 'description')}
                  title={capitalizeFirstLetter(t('description'))}
                  name="description"
                  error={poiData.errors.find(error => error.field === 'description')}
                  value={poiData.description}
                  readonly={savingPoi}
                />

                <TextInput
                  handleChange={(value) => handleChange(value, 'file')}
                  title={capitalizeFirstLetter(t('file'))}
                  name="file"
                  type="file"
                  value={poiData.file}
                  accept="image/png, image/jpeg, image/jpg, video/mp4"
                  error={poiData.errors.find(error => error.field === 'file')}
                  readonly={savingPoi}
                  helper={'Maximum 15MB'}
                />

                {/* <button onClick={(e) => clearFile(e)}>Clear</button> */}

                <div className={showDuration ? '' : 'd-none'}>
                  <TextInput
                    handleChange={(value) => handleChange(value, 'duration')}
                    title="Duration"
                    name="duration"
                    type="number"
                    error={poiData.errors.find(error => error.field === 'duration')}
                    value={poiData.duration}
                    readonly={savingPoi}
                  />
                </div>
              </div>
              <div className="card-footer">
                {(!savingPoi) ? (
                  <input
                    type="submit"
                    className='btn btn-primary'
                    value={t('create poi')}
                    disabled={poiData.errors.length > 0}
                  />
                ) : (
                  <button className='btn btn-primary' disabled>
                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span className="ms-2" role="status">{capitalizeFirstLetter(t('saving'))}</span>
                  </button>
                )}

                <button
                  onClick={(e) => closeForm(e)}
                  className="ms-2 btn btn-secondary"
                >
                  {t('close')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
