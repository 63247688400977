import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
 export const b2cPolicies = {
    names: {
        signUpSignIn: process.env.REACT_APP_AUTH_SIGNUP_AUTHORITY,
        // editProfile: 'B2C_1_default2'
        signIn: process.env.REACT_APP_AUTH_SIGNIN_AUTHORITY
    },
    authorities: {
        signUpSignIn: {
            // authority: 'https://dri3macc.b2clogin.com/dri3macc.onmicrosoft.com/B2C_1_default2'
            authority: process.env.REACT_APP_AUTH_BASE_AUTHORITY + process.env.REACT_APP_AUTH_SIGNUP_AUTHORITY
        },
        // editProfile: {
        //     authority: 'https://dri3macc.b2clogin.com/dri3macc.onmicrosoft.com/B2C_1_default2'
        //     authority: process.env.REACT_APP_AUTH_AUTHORITY
        // },
        signIn: {
            authority: process.env.REACT_APP_AUTH_BASE_AUTHORITY + process.env.REACT_APP_AUTH_SIGNIN_AUTHORITY
        }
    },
    authorityDomain: process.env.REACT_APP_AUTH_KNOWN_AUTH
}

export const loggerOptions = () => {
    if (process.env.REACT_APP_ENV === 'dev') {
        return {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            logLevel: 3,
            piiLoggingEnabled: true
        }
    }
    return {}
}

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        // authority: b2cPolicies.authorities.signUpSignIn.authority,
        authority: b2cPolicies.authorities.signIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT,
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: 'localStorage',
        // storeAuthStateInCookie: isIE || isEdge || isFirefox
        storeAuthStateInCookie: true
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: loggerOptions,
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
    }
};

// const msalSignUpSignInConfig = { ...msalConfig }
// msalSignUpSignInConfig.auth.authority = b2cPolicies.authorities.signUpSignIn.authority
// export { msalSignUpSignInConfig }

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    // scopes: ["https://msidlabb2c.onmicrosoft.com/msidlabb2capi/read"]
    scopes: ['openid', 'profile', process.env.REACT_APP_AUTH_CLIENT_ID],
}

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig = {
    // scopes: ['https://mediligoacc.onmicrosoft.com/9ab697eb-d796-4187-aee7-e7ad20fb0d8a/.default'],
    scopes: [process.env.REACT_APP_AUTH_SCOPE],
    uri: process.env.REACT_APP_API_URL
};
