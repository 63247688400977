import OrganisationRow from "./OrganisationRow"
import EmptyCard from '../Views/EmptyCard'

export default function OrganisationsList({ organisations, setOrganisation, setIsLoadingOrganisation }) {
  if (organisations.length === 0) {
    return (
      <EmptyCard
        emptyText='No organisations found'
      />
    )
  } else {
    return (
      <div className='d-flex align-items-center'>
        <table className='table table-bordered'>
          <thead>
            <tr className=''>
              <th>Customer Id</th>
              <th>Community</th>
              <th>Name</th>
              <th>License</th>
              <th>Left envelop</th>
              <th>End date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {organisations.map(
              (organisation) =>
                <OrganisationRow
                  key={organisation.id}
                  index={organisation.id.toString()}
                  organisation={organisation}
                  setOrganisation={setOrganisation}
                  setIsLoadingOrganisation={setIsLoadingOrganisation}
                />
            )}
          </tbody>
        </table>
      </div>
    )
  }
}