import callApi from "../../../utils/ApiCall"
import { useCallback, useEffect, useState, useRef } from "react"
import Loader from "../../Views/Loader"
import { Wrapper, Status } from "@googlemaps/react-wrapper"
import Map from './NewMap'
import MiniMap from "./MiniMap"
import Distance from '../../Views/Distance'
import Finish from "./Finish"
import { bearingToPoint, calcDistance, submitSuccess, submitError, successToast, errorToast } from '../../Helpers'
// import LegInfo from "./LegInfo"
import { useTranslation } from "react-i18next" 
import NoPano from './NoPano'
import RouteInfo from "./RouteInfo"

export default function StreetView({ authType, organisation, checkLicense, id }) {
  const [ data, setData ] = useState({
    // route: null,
    steps: [],
    routePoints: [],
    isLoadingRoute: true,
    emptyRouteData: false,
    isSavingRoute: false,
    routeSaved: false,
    travelmode: '',
    directions: [],
    description: '',
    distance: 0
  })

  const { t } = useTranslation('translation');

  const showRouteError = useCallback(() => {
    const updatedData = {
      ...data,
      isLoadingRoute: false,
      emptyRouteData: true
    }
    setData(updatedData)
  }, [data, setData])

  const loadRoute = useCallback(async (id) => {
    if (!data.isLoadingRoute) return

    let url
    if (authType === 'Manager') {
        url = `/api/manager/routes/${id}`
    } else {
        url = `/api/routes/${id}`
    }
    await callApi(url)
      .then(function (response) {
        // console.log('response: ', response)
        // if (response.success) {  
        // if (response.status === 200) {
        const routeData = response.data

        if (!routeData || !response.data.success) {
            showRouteError()
            return
        }

        checkLicense(response.data.community)

        // console.log('Loaded route: ', routeData)

        const overview_points = routeData.overview_path.map((point) => { return { lat: point.lat, lng: point.lng } })
        // console.log('routeData.steps: ', routeData.steps);
        // const overview_points = routeData.steps.map((point) => { return { lat: point[0], lng: point[1] } })

        let bearing = 0
        let distanceFromStart = 0
        for (let index = 0; index < overview_points.length; index++) {
          const currentStep = overview_points[index];
          const nextStep = overview_points[index + 1];
          const distance = nextStep ? calcDistance(currentStep, nextStep) : 0
  
          if (nextStep) {
            bearing = bearingToPoint(currentStep, nextStep)
          }
          currentStep.distanceToNextPoint = distance
          // console.log('distance: ', distance);
          currentStep.distanceFromStart = distanceFromStart
          // console.log('bearing: ', bearing);
          currentStep.heading = bearing
          distanceFromStart += distance
        }

        // console.log('overview_points: ', overview_points);

        setData({
            ...data,
            isLoadingRoute: false,
            routePoints: routeData.routepoints,
            directions: routeData.directions,
            steps: overview_points,
            travelmode: routeData.travelmode,
            description: routeData.description,
            distance: routeData.distance
        })

        return
      }).catch(async function (err) {
        // MsgActions.add('Route.LoadFailed.popup');
        const error = await err.json()
        console.error(`Loading route failed:\n${error}`);
        showRouteError()
      })
  }, [authType, checkLicense, data, setData, showRouteError])

  useEffect(() => {
    if (data.isLoadingRoute) loadRoute(id)
  }, [id, loadRoute, data])

  // const directionsRequestCallback = useCallback( async(result) => {
  //   //Check status, save the result
  //   console.log('directionsRequestCallback: ', result);

  //   return result
  // }, [])

  // const zoom = 14
  
  const mapRender = (status) => {
    return (
      <div className='h-100 d-flex align-items-center'>
        {status === Status.FAILURE ? (
          // 'Error loading the map.'
          'Map error'
        ) : (
          <Loader />
        )}
      </div>
    )
    // switch (status) {
    //   case Status.LOADING:
    //     return <Loader />;
    //   case Status.FAILURE:
    //     return 'Error loading the map.';
    //   case Status.SUCCESS:
    //     return <Map />;
    //   default:
    //     return ''
    // }
  };

  // const address = `${organisation.street1}, ${organisation.postalcode} ${organisation.city}, ${organisation.country_name}`

  return (
    <>
      {data.isLoadingRoute ? (
        <Loader />
      ) : (
        <>
          {data.emptyRouteData ? (
            <div className="h-100 w-100">
              <div className='col-4 mx-auto my-5 d-flex align-items-center justify-content-center'>
                <div className="card p-4">
                  <h2>{t('route not found')}</h2>
                  <p>
                    {/* Something went wrong. Please contact the support. */}
                    {t('something went wrong')} 
                  </p>
                  <a href="mailto:info@memomove.eu">{t('contact support')}</a>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="z-3 w-100 px-4 py-2 text-bg-danger position-absolute">
                <div className="w-100 text-center">
                  {/* Do not close this window. The route is being created. Please wait until the route is finished. */}
                  { t('do not close the window') }
                </div>
              </div>

              <Finish
                authType={authType}
                organisation={organisation}
                id={id}
              />

              <RouteInfo
                data={data}
              />

              {/* <LegInfo /> */}
              <NoPano />

              <Wrapper
                apiKey={process.env.REACT_APP_MAPS_API_KEY}
                render={mapRender}
              >
                <MiniMap
                  routePoints={data.routePoints}
                  directionsData={data.directions}
                  // routeSteps={data.steps}
                  routeTravelMode={data.travelmode}
                  authType={authType}
                />

                <div className="position-absolute z-3 w-100" style={{ bottom: '20px' }}>
                  <div className="w-50 d-flex mx-auto align-items-center">
                    <Distance />
                  </div>
                </div>

                <Map
                  directionsData={data.directions}
                />
              </Wrapper>
            </>
          )}
        </>
      )}
    </>
  )
}