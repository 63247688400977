import React from 'react'
import callApi from '../../utils/ApiCall'
import { submitSuccess, submitError, successToast, errorToast, pageLoader } from '../Helpers'
import { Wrapper, Status } from "@googlemaps/react-wrapper"
import Map from '../Views/Map'
import RoutePointList from './RoutePointList'
import Loader from '../Views/Loader'
import CheckBox from '../Form/CheckBox'
import CreateStore from '../../stores/CreateStore'

class CreateCycleRoute extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      markers: [],
      zoom: 14,
      // center: { lat: 51.0441725, lng: 3.7326434 },
      center: '',
      address: '',
      routePoints: [],
      routeSaving: false,
      // routeSaved: false,
      // routeId: null,
      countryCodes: [],
      formErrorFields: [],
      formData: {
        // community: '',
        description: '',
        directions: []
      },
      routeDrawn: false,
      totalDistance: 0,
    }
    // this.totalDistance = 0
    // this.formData = {
    //   description: ''
    // }

    // this.updateState = this.updateState.bind(this)
    // this.updateCenter = this.updateCenter.bind(this)
    // this.removeRoutePoint = this.removeRoutePoint.bind(this)
    // this.drawRoute = this.drawRoute.bind(this)
    // this.directionResult = null
    // this.saveRoute = this.saveRoute.bind(this)
    // this.toggleStreetViewLayer = this.toggleStreetViewLayer.bind(this)
  }

  componentDidMount() {
    this.props.setPageClasses('Routes')

    const organisation = this.props.organisation
    const address = `${organisation.street1}, ${organisation.postalcode} ${organisation.city}, ${organisation.country_name}`
    this.setState({ address })
  }

  // async getRouteDistance() {
  //   let totalDistance = 0

  //   const legs = this.directionResult['routes'][0]['legs']
  //   // console.log('legs: ', legs);
  //   legs.forEach(leg => {
  //     // console.log('leg: ', leg.distance.value);
  //     totalDistance += leg.distance.value
  //   })

  //   // console.log('new totalDistance: ', totalDistance);
  //   // console.log('prev totalDistance: ', this.totalDistance);


  //   // if (this.state.totalDistance !== totalDistance) {
  //   //   const that = this
  //   //   this.setState({ totalDistance }, async () => {
  //   //     console.log('setting total Distance state..');
  //   //     await that.checkDistanceAllowed(totalDistance)
  //   //   })
  //   // }

  //   if (this.totalDistance !== totalDistance) {
  //     // const that = this
  //     const updatedDirections = this.directionResult

  //     const updatedFormData = { ...this.state.formData, directions: updatedDirections }
  //     this.totalDistance = totalDistance
  //     this.setState({
  //       totalDistance,
  //       formData: updatedFormData
  //     }, () => {
  //       console.log('formData: ', this.state.formData);
  //     })

  //     // console.log('current totalDistance: ', this.totalDistance);
  //     // this.setState({ totalDistance }, async () => {
  //     //   console.log('setting total Distance state..');
  //     // if (this.props.authType !== 'Manager' && this.props.routeType !== 'Free') {
  //     if (this.props.routeType !== 'Free') {
  //       await this.checkDistanceAllowed(totalDistance)
  //     }
  //     // })
  //   }

  //   // return totalDistance
  // }

  // async directionsRequestCallback(result) {
  //   // Check status, save the result
  //   // console.log(result);
  //   // const legs = result['routes'][0]['legs']
  //   // let all_steps = legs.map((leg) => {
  //   //   return leg.steps.map(step => {
  //   //     return step.lat_lngs.map(lat_lngs => [lat_lngs.lat(), lat_lngs.lng()])
  //   //   })
  //   // })

  //   // if ('OK' === status) {
  //     // newDirectionResult = true
  //     // lastDirectionsRequestValid = true
  //     return result
  //   // } else {
  //   //   console.warn(`Unexpected status, was not OK but ${status} instead`);
  //   // }
  // }

  // async checkDistanceAllowed(distance) {
  //   const that = this

  //   // console.log('this.props.authType: ', this.props.authType);

  //   let url
  //   if (this.props.authType === 'Manager') {
  //     url = `/api/${this.props.organisation.uuid}/routes/create/allowed/${distance}`
  //   } else {
  //     url = `/api/routes/create/allowed/${distance}`
  //   }

  //   if (this.props.routeType === 'Shared') {
  //     url += '/shared'
  //   }
    
  //   await callApi(url)
  //     .then(function (response) {
  //       // console.log(response)
  //       if (!response.data.distance_allowed) {
  //         const distanceAllowedError = {
  //           field: 'distance',
  //           message: that.props.t('distance error')
  //           // 'Not enough credits left to create the route. Please shorting your route.'
  //         }
  //         const formErrorFields = [ ...that.state.formErrorFields ]
  //         formErrorFields.push(distanceAllowedError)
  //         that.setState({ formErrorFields })
  //       } else {
  //         that.removeDistanceError()
  //       }
  //     })
  //     .catch(function (error) {
  //       console.error(error)
  //     });
  // }

  // routeData(formData) {
  //   // if (this.directionResult === null) return
  //   // console.log('this.directionResult: ', this.directionResult)
  //   // // const overview_path = routes['overview_path'].map((element) => [element.lat(), element.lng()])
  //   // // const legs = routes['legs']

  //   const directionResult = CreateStore.getDirectionsResult()

  //   const routes = directionResult.routes[0]
  //   const legs = routes.legs
  //   const steps = legs.map((leg) => {
  //     return leg.steps.map(step => {
  //       return step.lat_lngs.map(lat_lngs => [lat_lngs.lat(), lat_lngs.lng()])
  //     })
  //     // return [element.lat(), element.lng()]
  //   })

  //   // const distance = legs[0].distance.value
  //   const request = directionResult.request
  //   const origin_point = request.origin.location.toJSON()
  //   const destination_point = request.destination.location.toJSON()

  //   // var polyline = require('@mapbox/polyline');
  //   // const points = this.directionResult['routes'].map((route) => {
  //   //   const decoded_polyline = polyline.decode(route['overview_polyline']);
  //   //   console.log(decoded_polyline);
  //   //   return decoded_polyline
  //   // })

  //   // console.log('points: ', points);
  //   const routePoints = CreateStore.getRoutePoints()
  //   const countryCodes = CreateStore.getCountryCodes()

  //   return {
  //     community: formData.community,
  //     description: formData.description,
  //     // routepoints: this.state.routePoints,
  //     routepoints: routePoints,
  //     start_point: [origin_point.lat, origin_point.lng],
  //     end_point: [destination_point.lat, destination_point.lng],
  //     waypoints: request['waypoints'],
  //     distance: formData.totalDistance,
  //     // travelMode: request['travelMode'],
  //     bounds: routes['bounds'],
  //     // overview_polyline: this.directionResult['overview_polyline'],
  //     overview_polyline: directionResult.overview_polyline,
  //     overview_path: routes['overview_path'],
  //     summary: routes['summary'],
  //     warnings: routes['warnings'],
  //     waypoint_order: routes['waypoint_order'],
  //     steps: steps.flat(2),
  //     country_code: countryCodes.length === 0 ? '' : countryCodes.join(', '),
  //     directions: directionResult
  //   }
  // }

  // saveRoute(e, data) {
  //   e.preventDefault()
  //   // console.log(description);
  //   // console.log(this.routeData(formData));

  //   // const { formData } = this.state
  //   const formData = { ...data }
  //   console.log('saveRoutes: ', formData);

  //   const routeData = this.routeData(formData)
  //   // console.log('routeData: ', routeData);
  //   if (routeData === undefined) return

  //   // routeData['description'] = description
  //   routeData['community'] = this.props.routeType

  //   const that = this

  //   let url
  //   if (this.props.authType === 'Manager' && this.props.routeType !== 'Free') {
  //     url = `/api/${this.props.organisation.uuid}/routes/create`
  //   } else if (this.props.authType === 'Manager') {
  //     url = `/api/routes/free/create`
  //   } else {
  //     url = `/api/routes/create`
  //   }

  //   this.setState({
  //     routeSaving: true,
  //     formData: {
  //       ...that.formData,
  //       description: routeData['description'],
  //       community: routeData['community']
  //     }
  //   }, () => {
  //     // axios.post(`${apiUrl}/organisations/${orgUuid}/routes/create`, {
  //     callApi(
  //       url,
  //       'POST',
  //       routeData
  //     )
  //     .then(function (response) {
  //       // console.log(response)
  //       if (submitSuccess(response)) {
  //         successToast(
  //           that.props.t('route map created')
  //         )
  //         that.setState({
  //           routeId: response.data.route.id
  //         })
  //         that.checkRouteInterval()
  //       }
  //     })
  //     .catch(async function (error) {
  //       const response = await error.json()
  //       if (submitError(error)) {
  //         // console.log(response);
  //         errorToast(that.props.t(response.message))
  //         // console.log(routeData['description'])
  //         that.setState({
  //           routeSaving: false,
  //           formErrorFields: response.fields,
  //           // formData: routeData
  //         })
  //       }
  //     });
  //   })
  // }

  // checkRouteInterval() {
  //   const that = this

  //   let looper = setInterval(async () => {
  //     let output = await this.checkRouteStatus()
  //     // console.log(output);
  //     if (output.id) {
  //       that.setState({ routeSaved: true }, () => {
  //         clearInterval(looper);
  //         successToast(
  //           // 'First run! Creating steps'
  //           that.props.t('first run')
  //         )
  //         that.props.navigate(`/routes/${output.id}/create`)
  //       })
  //     }
  //   }, 1500);
  // }

  // async checkRouteStatus() {
  //   let url
  //   if (this.props.authType === 'Manager' && this.props.routeType !== 'Free') {
  //     url = `/api/${this.props.organisation.uuid}/routes/${this.state.routeId}/status`
  //   } else if (this.props.authType === 'Manager') {
  //     url = `/api/routes/free/${this.state.routeId}/status`
  //   } else {
  //     url = `/api/routes/${this.state.routeId}/status`
  //   }
  //   return await callApi(url)
  //     .then(function (response) {
  //       // console.log(response)
  //       return response.data
  //     })
  //     .catch(async function (error) {
  //       const errorResponse = await error.json()
  //       console.error(errorResponse)
  //     });
  // }

  toggleStreetViewLayer(checked) {
    CreateStore.setShowLayer(checked)
  }

  render() {
    const { address, center, routeSaving } = this.state
    const { t } = this.props
    const divStyle = {
      width: 'auto',
      height: '100%'
    }

    const mapRender = (status) => {
      return (
        <div className='h-100 d-flex align-items-center'>
          {status === Status.FAILURE ? (
            // 'Error loading the map.'
            t('map error')
          ) : (
            <Loader />
          )}
        </div>
      )
      // switch (status) {
      //   case Status.LOADING:
      //     return <Loader />;
      //   case Status.FAILURE:
      //     return 'Error loading the map.';
      //   case Status.SUCCESS:
      //     return <Map />;
      //   default:
      //     return ''
      // }
    };

    return (
      <>
        {routeSaving ? (
          <div className='d-flex flex-column w-25 mx-auto text-center p-4 border rounded-3'>
            <>
              <h4 className='mb-4'>
                {/* Creating the route map. */}
                {t('creating route map')}
              </h4>
              {pageLoader}
            </>
          </div>
        ) : (
          <Wrapper apiKey={process.env.REACT_APP_MAPS_API_KEY} render={mapRender}>
            <div className='row h-100'>
              <div className='col'>
                <RoutePointList
                  authType={this.props.authType}
                  type={this.props.routeType}
                  organisationUuid={this.props.organisation.uuid}
                  t={t}
                />
              </div>
              <div className='col-9 border p-0 position-relative'>
                <small className='position-absolute z-3 bg-white px-2' style={{ 'right': 0 }}>
                  <CheckBox
                    onChange={(checked) => this.toggleStreetViewLayer(checked)}
                    label='Streetview layer'
                    defaultValue={true}
                  />
                </small>
                <Map
                  divStyle={divStyle}
                  address={address}
                  center={center}
                />
              </div>
            </div>
          </Wrapper>
        )}
      </>
    )
  }
}

export default CreateCycleRoute
