import PropTypes from 'prop-types'
import _ from 'lodash'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export default function TextInput({ handleChange, title, name, value = '', type, readonly, focus, error, helper, accept = ''}) {
  TextInput.propTypes = {
    handleChange: PropTypes.func,
    title: PropTypes.string,
    name: PropTypes.string,
    // value: PropTypes.string || PropTypes.number,
    type: PropTypes.string,
    readonly: PropTypes.bool,
    focus: PropTypes.bool,
    error: PropTypes.object,
    helper: PropTypes.string
  }
  
  TextInput.defaultProps = {
    handleChange: PropTypes.func,
    title: '',
    name: '',
    // value: '',
    type: 'string',
    readonly: false,
    focus: false,
    error: {},
    helper: ''
  }

  const { t } = useTranslation()

  let inputClassNames = 'form-control'
  if (readonly) {
    inputClassNames += ' text-bg-light'
  } else if (!_.isEmpty(error)) {
    inputClassNames += ' border-danger'
  }
  const labelClassNames = `d-flex ${_.isEmpty(error) ? '' : 'text-danger'}`

  const onChange = useCallback((e) => {
    const value = type === 'file' ? e.target.files[0] : e.target.value
    handleChange(value)
  }, [type, handleChange])

  return (
    <>
      {type === 'hidden' ? (
        <input type={type} value={value.toString()} id={name} name={name} className={inputClassNames} readOnly={readonly} autoFocus={focus} />
      ) : (
        <div className='my-2'>
          <label htmlFor={name} className={labelClassNames}>
            {title}
            {helper && (
              <small className='ms-auto text-danger-emphasis'>{helper}</small>
            )}
          </label>
          <div className="from-group">
            {type === 'file' ? (
              <input type={type} id={name} name={name} onChange={(e) => onChange(e)} className={inputClassNames} readOnly={readonly} accept={accept} />
            ) : (
              <>
                {type === 'number' ? (
                  <input type={type} value={value} step="1" min="0" id={name} name={name} onChange={(e) => onChange(e)} className={inputClassNames} readOnly={readonly} autoFocus={focus} />
                ) : (
                  <input type={type} value={value.toString()} id={name} name={name} onChange={(e) => onChange(e)} className={inputClassNames} readOnly={readonly} autoFocus={focus} />
                )}
              </>
            )}
            {!_.isEmpty(error) && (
              <small className='text-danger'>
                {error.message === 'entity_to_large' ? (
                  <>
                  { t(error.message) }
                  </>
                ) : (
                  <>
                    {error.message}
                  </>
                )}
              </small>
            )}
          </div>
        </div>
      )}
    </>
  )
}
