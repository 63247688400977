import ClientSideNavigation from './ClientSideNavigation'
import Authorizer from './Authorizer';

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { MsalProvider } from "@azure/msal-react";

function App({ pca }) {
  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <Authorizer />
        <ToastContainer />
      </MsalProvider>
    </ClientSideNavigation>
  )
}

export default App
