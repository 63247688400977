import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"
import { capitalizeFirstLetter } from "../Helpers"

export default function PermissionError({ error, recheckPermissions, handleLogout }) {
  const containerStyles = {
    height: '100vh',
    width: '100vw'
  }

  const navigate = useNavigate()
  
  const redirectToIndex = () => {
    recheckPermissions()
    navigate('/')
  };

  const { t } = useTranslation()

  return (
    <div style={containerStyles}>
      <div className='text-center mx-auto my-5'>
        <div className='mb-5 d-inline-flex align-items-center'>
          <img src='/static/img/logo_website.png' alt='Logo Memomove' />
          <h4 className='ms-2 mb-0'>
            Memomove
          </h4>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="mx-auto p-4 alert alert-danger bg-white">
          <h1 className="mb-4">
            {/* Something went wrong! */}
            { t('something went wrong')}
          </h1>
          {error.message ? (
            <p>
              {error.message}
            </p>
          ) : (
            <>
              {/* You do not have the required permission to view this page. */}
              <p>{ t('access denied') }</p>
              {/* <small>Error: {error.code}</small> */}
            </>
          )}
          <div className="mt-4 d-flex align-items-center">
            <button onClick={() => handleLogout()} className='btn btn-primary'>
              { capitalizeFirstLetter(t('sign out')) }
            </button>
            {/* <button onClick={() => redirectToIndex()} className='ms-2 btn btn-secondary'>
              { capitalizeFirstLetter(t('back to index'))}
            </button> */}
            <a href="mailto:info@memomove.eu" subject="Memomove Admin - Permission problems" className="ms-auto fs-small">
              { t('contact us') }
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
