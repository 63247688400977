import assign from 'object-assign'
import EventEmitter from 'events'

const DISTANCE_CHANGED = "onDistanceChange"
const END_CHANGED = "onEndChange"
const PAUSE_CHANGED = "onPauseChange"
const POSITION_CHANGED = "onPositionChange"
const STEPS_CHANGED = "onStepsChange"
const SPEED_CHANGED = "onSpeedChange"
// const MAP_INIT_CHANGED = "onMapInitChange"
const PANO_STATUS_CHANGED = "onPanoStatusChange"

// let totalTime = 0
let totalDistance = 0
let atEnd = false
let paused = false
let forwardSpeed = 3000
// let mapInit = false
let panoStatus = 'Ok'

let currentPosition = {
  lat: null,
  lng: null,
  heading: null
}

const steps = []

const MemoStore = assign({}, EventEmitter.prototype, {  
  getForwardSpeed: function() {
    return forwardSpeed
  },

  resetForwardSpeed: function() {
    forwardSpeed = 3000
  },

  changeForwardSpeed: function(speed) {
    forwardSpeed = speed
    this.emit(SPEED_CHANGED)
  },

  addForwardSpeedChangeListener: function(callback) {
    this.on(SPEED_CHANGED, callback)
  },

  removeForwardSpeedChangeListener: function(callback) {
    this.removeListener(SPEED_CHANGED, callback)
  },

  addStep: function(step) {
    steps.push(step)
    this.emit(STEPS_CHANGED);
    totalDistance += step.distanceMoved
    this.emit(DISTANCE_CHANGED);
  },

  getSteps: function() {
    return steps
  },

  addPositionChangeListener: function(callback) {
    this.on(POSITION_CHANGED, callback)
  },

  removePositionChangeListener: function(callback) {
    this.removeListener(POSITION_CHANGED, callback)
  },

  addDistanceChangeListener: function(callback) {
    this.on(DISTANCE_CHANGED, callback)
  },

  removeDistanceChangeListener: function(callback) {
    this.removeListener(DISTANCE_CHANGED, callback)
  },

  addEndChangeListener: function(callback) {
    this.on(END_CHANGED, callback)
  },

  removeEndChangeListener: function(callback) {
    this.removeListener(END_CHANGED, callback)
  },

  addPauseChangeListener: function(callback) {
    this.on(PAUSE_CHANGED, callback)
  },

  removePauseChangeListener: function(callback) {
    this.removeListener(PAUSE_CHANGED, callback)
  },

  addDistance: function(distance) {
    totalDistance += distance
    this.emit(DISTANCE_CHANGED);
  },

  getTotalDistance: function() {
  	return totalDistance
  },

  resetDistance: function() {
    totalDistance = 0
  },

  setEndStatus: function(endStatus) {
    atEnd = endStatus
    this.emit(END_CHANGED);
  },

  getEndStatus: function() {
    return atEnd
  },

  setPaused: function(newVal) {
    paused = newVal
    this.emit(PAUSE_CHANGED);
  },

  getPaused: function () {
    return paused
  },

  setCurrentPosition: function(newPosition) {
    currentPosition = newPosition
    this.emit(POSITION_CHANGED)
  },

  getCurrentPosition: function() {
    return currentPosition
  },

  setPanoStatus: function (status) {
    panoStatus = status
    this.emit(PANO_STATUS_CHANGED)
  },

  getPanoStatus: function() {
    return panoStatus
  },

  addPanoStatusChangeListener: function(callback) {
    this.on(PANO_STATUS_CHANGED, callback)
  },

  removePanoStatusChangeListener: function(callback) {
    this.removeListener(PANO_STATUS_CHANGED, callback)
  }
})

export default MemoStore
