import { useCallback, useEffect, useState, useRef } from "react"
import PoiStore from "../../../stores/PoiStore"
import MemoStore from "../../../stores/MemoStore"
import PoiContent from "./PoiContent"
import { successToast } from "../../Helpers"
import DirectionsStore from "../../../stores/DirectionsStore"

export default function Poi() {
  const [ show, setShow ] = useState(false)
  const [ pois, setPois ] = useState([])
  const poiIndex = useRef(0)
  const timeoutInterval = useRef()
  const [ currentPoi, setCurrentPoi ] = useState()

  const prevPoi = useCallback(() => {
    poiIndex.current--
    setCurrentPoi(pois[poiIndex.current])
  }, [setCurrentPoi, pois])

  const leftArrow = useCallback(() => {
    if (timeoutInterval.current) {
      clearInterval(timeoutInterval.current)
    }
    prevPoi()
  }, [prevPoi])

  const closePopup = useCallback(() => {
    PoiStore.showPoi(false)
    MemoStore.setPaused(false)
    clearInterval(timeoutInterval.current)
    setShow(false)
  }, [])

  const nextPoi = useCallback(() => {
    poiIndex.current++
    const nextPoi = pois[poiIndex.current]

    if (!nextPoi) {
      closePopup()
      return
    }
    setCurrentPoi(pois[poiIndex.current])
  }, [closePopup, setCurrentPoi, pois])

  const rightArrow = useCallback(() => {
    if (timeoutInterval.current) {
      clearInterval(timeoutInterval.current)
    }
    nextPoi()
  }, [nextPoi])

  const nextPoiTimeout = useCallback(() => {
    if (poiIndex === pois.length - 1) return
    if (currentPoi.content_type.includes('video')) return

    timeoutInterval.current = setTimeout(() => {
      nextPoi()
    }, currentPoi.duration * 1000 + 700);
  }, [pois, nextPoi, currentPoi])

  const showPopup = useCallback(() => {
    poiIndex.current = 0
    const show = PoiStore.getShowPoi()
    const pois = PoiStore.getPois(DirectionsStore.getCurrentStepIndex())
    setPois(pois)
    setCurrentPoi(pois[poiIndex.current])
    setShow(show)
  }, [setCurrentPoi, poiIndex])

  const removePoi = useCallback((poi) => {
    const updatedPois = pois.filter(p => p !== poi)
    const currentStepIndex = DirectionsStore.getCurrentStepIndex()
    PoiStore.setPois(currentStepIndex, updatedPois)

    successToast('Poi removed!')
    clearInterval(timeoutInterval.current)
    if (updatedPois.length === 0) {
      closePopup()
    } else {
      setPois([...updatedPois])
      // if (!updatedPois[poiIndex.current]) {
      //   poiIndex.current = 0
      // }
      setCurrentPoi(updatedPois[0])
    }
  }, [pois, closePopup, setPois])

  useEffect(() => {
    PoiStore.addShowListener(showPopup)

    return () => {
      PoiStore.removeShowListener(showPopup)
    }
  }, [currentPoi, showPopup, pois])

  if (show) {
    return (
      <>
        <div className="position-absolute bg-opacity-50 bg-dark w-100 h-100 z-3">
          <div className={'w-100 d-flex justify-content-around h-100 align-items-center'}>
            <div className="position-relative w-50 d-flex align-items-center">
              <div>
                {pois.length > 1 && poiIndex.current > 0 && (
                  <button
                    onClick={() => leftArrow()}
                    className="btn btn-dark position-absolute"
                    style={{ zIndex: '10', left: '-2rem' }}
                  >
                    <i className="fa fa-arrow-left"></i>
                  </button>
                )}
              </div>

              <PoiContent
                poi={currentPoi}
                closePopup={closePopup}
                loading={true}
                nextPoiTimeout={nextPoiTimeout}
                goToNext={nextPoi}
                removePoi={removePoi}
              />

              <div>
                {pois.length > 1 && poiIndex.current < pois.length - 1 && (
                  <button
                    onClick={() => rightArrow()}
                    className="btn btn-dark position-absolute"
                    style={{ zIndex: '10', right: '-2rem' }}
                  >
                    <i className="fa fa-arrow-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}