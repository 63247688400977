import React from 'react';
import { Link } from 'react-router-dom';

class EmptyCard extends React.Component {
  render () {
    const { createUrl = '', emptyText, buttonText = '', showBtn = false } = this.props

    return (
      <div className="card">
        <div className="card-body text-center">
          {emptyText}
          {showBtn ? (
            <>
              <br /><br />
              <Link to={createUrl} className='btn btn-primary btn-sm'>
                {buttonText}
              </Link>
            </>
          ) : ( '' )}
        </div>
      </div>
    )
  }
}

export default EmptyCard;
