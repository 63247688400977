import { useCallback, useEffect, useRef } from "react"
import Timer from './Timer'
import Finish from './Finish'
import Distance from '../../Views/Distance'
import Pause from "./Pause"
import MemoStore from '../../../stores/MemoStore';
import PoiStore from "../../../stores/PoiStore"
import SpeedSlider from "./SpeedSlider"
import DirectionsStore from "../../../stores/DirectionsStore"
import callApi from "../../../utils/ApiCall"
import NewPoi from "./NewPoi"
import Poi from "./Poi"
import PoiButton from "./PoiButton"
import { successToast } from "../../Helpers"
import { useTranslation } from "react-i18next"

export default function Map({ steps, id, authType, orgUuid }) {
  const { t } = useTranslation()
  const mapRef = useRef()
  const sv = useRef()
  // const center = useRef()
  // const zoomLevel = 14
  const currentStep = useRef()
  const timeBetweenSteps = useRef()
  const moveForwardInterval = useRef()
  const keyListener = useRef()
  const timerInterval = useRef()
  // const moveForwardLastStart = useRef()
  const panorama = useRef()

  const updateTotalDistance = useCallback((distance) => {
    DirectionsStore.addStepDistance(distance)
  }, [])

  const mapStyle = {
    width: 'auto',
    height: '100%'
  }

  const setPanoramaImage = useCallback(async (pos) => {
    // console.log('map: ', map);
    // console.log('setPanoramaImage');
    panorama.current.setOptions({
      addressControl: false,
      imageDateControl: false,
      clickToGo: false,
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      fullscreenControl: false,
      scrollWheel: false,
      panControl: false,
      motionTracking: false,
      zoomControl: false,
      enableCloseButton: false,
      keyboardShortcuts: false,
      panningGesturesEnabled: false,
      zoomGesturesEnabled: false
    })
    // ; // TODO fix type
    // console.log('pos: ', pos);

    // panorama.current.setPosition(pos.location)
    // panorama.current.setPano(pos.pano_id)
    // panorama.current.setPov(
    //   /** @type {google.maps.StreetViewPov} */ {
    //     heading: pos.heading,
    //     pitch: pos.pitch,
    //   },
    // )
    // panorama.current.setVisible(true);
    // updateTotalDistance(pos.distance_moved)
    sv.current.getPanorama({
      location: pos.location,
      preference: 'best',
      sources: ['outdoor']
    }).then((data, status) => {
      // setTimeout(async() => {
        // panorama.current.setPosition(pos.location)
        panorama.current.setPano(pos.pano_id)
        panorama.current.setPov(
          /** @type {google.maps.StreetViewPov} */ {
            heading: pos.heading,
            pitch: pos.pitch,
          },
        )
        panorama.current.setVisible(true)
        // console.log('pos: ', pos);
        updateTotalDistance(pos.distance_moved)
        MemoStore.setPaused(false)
      // }, 1000)
    }).catch(() => {
      console.error("Street View data not found for this location.")
    })
  }, [panorama, updateTotalDistance])

  const pauseTimer = useCallback(() => {
    clearInterval(timerInterval.current)
  }, [])

  const pauseMoveForward = useCallback(() => {
    clearInterval(moveForwardInterval.current)
    // console.log('pauseMoveForward: ', moveForwardLastStart.current);
  }, [moveForwardInterval])

  const setEnd = useCallback(() => {
    clearInterval(moveForwardInterval.current)
    MemoStore.setEndStatus(true)

    pauseTimer()
  }, [moveForwardInterval, pauseTimer])

  const moveForward = useCallback(() => {
    // console.log('moveForward...');
    const currentIndex = steps.findIndex(step => step === currentStep.current)
    // console.log('currentIndex: ', currentIndex);
    const nextStep = DirectionsStore.getStep(currentIndex + 1)
    const pois = PoiStore.getPois(currentIndex + 1)
    // const nextStep = steps[currentIndex+1]

    // console.log('nextStep: ', nextStep);

    if (nextStep) {
      currentStep.current = nextStep
      // PoiStore.setPois(currentIndex + 1, nextStep.pois)
      PoiStore.setPois(currentIndex + 1, pois)
      DirectionsStore.setCurrentStepIndex(currentIndex + 1)
      DirectionsStore.setCurrentStep(nextStep)
      setPanoramaImage(nextStep)
    } else {
      setEnd()
    }
  }, [steps, currentStep, setPanoramaImage, setEnd])

  const loadStepPois = useCallback(async (routeId, step) => {
    let url
    if (authType === 'Manager') {
      url = `/api/manager/routes/${routeId}/steps/${step.uuid}/pois`
    } else {
      url = `/api/v2/routes/${routeId}/steps/${step.uuid}/pois`
    }
    await callApi(url)
      .then(function (response) {
        console.log('response: ', response);
        if (!response.data) {
          return
        }

        step.pois = response.data.pois
        // const currentIndex = steps.findIndex(step => step === currentStep.current)
        const currentIndex = steps.findIndex(s => s === step)
        PoiStore.setPois(currentIndex, step.pois)

        // console.log('step: ', step)
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse);
      })
  }, [authType, steps])

  const moveTwoStepsBack = useCallback(async (originStep) => {
    // const currentStep = DirectionsStore.getCurrentStep()
    // const currentIndex = steps.findIndex(step => step === currentStep.current)
    // const currentIndex = steps.findIndex(step => step === currentStep)
    const currentIndex = DirectionsStore.getCurrentStepIndex()
    // console.log('currentIndex: ', currentIndex);
    let step = 2
    let prevStep = steps[currentIndex - step]

    if (!prevStep) {
      step = 1
      prevStep = steps[currentIndex - step]
    }

    if (!prevStep) {
      step = 0
      prevStep = currentStep.current
    }

    successToast(
      'Moving back!'
    )

    // console.log('move back: ', step);

    if (step !== 0) {
      let revertDistance = 0
      for (let index = currentIndex; index >= currentIndex - step; index--) {
        const step = steps[index]
        console.log('step: ', step);
        revertDistance += step.distance_moved
      }
      // console.log('revertDistance: ', revertDistance)
      let currentDistance = DirectionsStore.getStepDistance()
      // console.log('currentDistance: ', currentDistance)

      DirectionsStore.setStepDistance(currentDistance - revertDistance)
      // console.log('updateDistance to: ', currentDistance - revertDistance)
    }
    
    // Reload pois for this step...
    
    currentStep.current = prevStep
    // console.log('currentStep.current: ', currentStep.current);
    DirectionsStore.setCurrentStep(prevStep)
    DirectionsStore.setCurrentStepIndex(currentIndex - step)
    PoiStore.setPois(currentIndex - step, prevStep.pois)

    await loadStepPois(id, originStep)

    setPanoramaImage(prevStep)

    // PoiStore.setShowForm(false)
    // MemoStore.setPaused(false)
  }, [steps, currentStep, id, loadStepPois, setPanoramaImage])

  const StartMoveForwardInterval = useCallback(() => {
    clearInterval(moveForwardInterval.current)
    moveForwardInterval.current = setInterval(() => {
      // moveForwardLastStart.current = performance.now()

      moveForward()
    }, timeBetweenSteps.current);
  }, [moveForwardInterval, moveForward, timeBetweenSteps])

  const changeForwardInterval = useCallback(() => {
    timeBetweenSteps.current = MemoStore.getForwardSpeed()

    if (MemoStore.getPaused()) return
    // const timeSpent = performance.now() - moveForwardLastStart.current
    // setTimeout(() => {
    //   moveForward()
      StartMoveForwardInterval()
    // }, timeBetweenSteps.current - timeSpent);
  // }, [timeBetweenSteps, moveForward, StartMoveForwardInterval])
  }, [StartMoveForwardInterval])

  const toggleMoveForward = useCallback(() => {
    const paused = MemoStore.getPaused()
    // console.log('toggleMoveForward: ', paused);
    if (paused) {
      pauseMoveForward()
    } else {
      StartMoveForwardInterval()
    }
  }, [pauseMoveForward, StartMoveForwardInterval])
  
  const toggleStreetView = useCallback(() => {
    setPanoramaImage(currentStep.current)

    StartMoveForwardInterval()
  }, [setPanoramaImage, StartMoveForwardInterval, currentStep])

  const onKey = useCallback((e) => {
    // const curmovement = new Date();
    // console.log('curmomvement: ', curmovement);
    // this.lastmovement = curmovement;

    // console.log(e);
    e.stopPropagation()

    // window.addEventListener(
    //   'keydown',
    //   (event) => {
    //     if (
    //       (
    //         // Change or remove this condition depending on your requirements.
    //         event.key === 'ArrowUp' || // Move forward
    //         event.key === 'ArrowDown' || // Move forward
    //         event.key === 'ArrowLeft' || // Pan left
    //         event.key === 'ArrowRight' || // Pan right
    //         event.key === '+' || // Zoom in
    //         event.key === '=' || // Zoom in
    //         event.key === '_' || // Zoom out
    //         event.key === '-' // Zoom out
    //       ) &&
    //       !event.metaKey &&
    //       !event.altKey &&
    //       !event.ctrlKey
    //     ) {
    //       event.stopPropagation()
    //     };
    //   },
    //   { capture: true },
    // );

    const intKey = (window.Event) ? e.which : e.keyCode;
    switch (intKey) {
      // case 37:
      // case 65:
      // case 81:
      //   // q or a
      //   this.turnLeft();
      //   break;
      // case 39:
      // case 68:
      //   // d
      //   this.turnRight();
      //   break;
      case 38:
      case 87:
      case 90:
        // w or z
        // moveForward();
        break;
      default:
        break;
    }
  }, [moveForward])

  const createMap = useCallback(async() => {
    if (!sv.current) {
      sv.current = new window.google.maps.StreetViewService()
    }
    if (!panorama.current) {
      panorama.current = new window.google.maps.StreetViewPanorama(mapRef.current)
    }

    const forwardSpeed = MemoStore.getForwardSpeed()
    timeBetweenSteps.current = forwardSpeed

    toggleStreetView()
  }, [toggleStreetView])

  const setLastRun = useCallback(async () => {
    if (authType === 'Manager') return
    const endStatus = MemoStore.getEndStatus()
    if (!endStatus) return

    const url = `/api/routes/${id}`

    await callApi(url, 'PUT', {})
      .then(function (response) {
        console.log('setLastRun', response)
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse)
      });
  }, [id, authType])

  const newPoi = useCallback(() => {
    console.log('newPoi');
    pauseTimer()
    pauseMoveForward()
    MemoStore.setPaused(true)
    PoiStore.setShowForm(true)
  }, [pauseTimer, pauseMoveForward])

  const handleSteps = useCallback(() => {
    DirectionsStore.setSteps(steps)

    steps.current = steps
    currentStep.current = steps[0]
    DirectionsStore.setCurrentStep(steps[0])
    DirectionsStore.setCurrentStepIndex(0)
    PoiStore.setPois(0, steps[0].pois)
  }, [steps])

  useEffect(() => {
    // console.log('steps: ', steps);

    if (steps.length === 0) return

    handleSteps()
    DirectionsStore.resetStepDistance()
    MemoStore.resetForwardSpeed()

    // currentStep.current = steps[0]
    // DirectionsStore.setCurrentStep(steps[0])
    // PoiStore.setPois(0, steps[0].pois)

    MemoStore.setEndStatus(false)
    createMap()

    window.addEventListener('keydown', (event) => onKey(event), { capture: true })
    MemoStore.addPauseChangeListener(toggleMoveForward)
    MemoStore.addForwardSpeedChangeListener(changeForwardInterval)
    MemoStore.addEndChangeListener(setLastRun)

    return () => {
      // DirectionsStore.removeStepsChangeListener(handleSteps)
      window.removeEventListener('keydown', (event) => onKey(event), { capture: true })
      MemoStore.removePauseChangeListener(toggleMoveForward)
      MemoStore.removeForwardSpeedChangeListener(changeForwardInterval)
      MemoStore.removeEndChangeListener(setLastRun)
      if (moveForwardInterval.current) clearInterval(moveForwardInterval.current)
    }
  }, [setLastRun, steps, handleSteps, createMap, keyListener, onKey, currentStep, updateTotalDistance, toggleMoveForward, changeForwardInterval, moveForwardInterval])

  return (
    <>
      <NewPoi
        authType={authType}
        orgUuid={orgUuid}
        id={id}
        moveTwoStepsBack={moveTwoStepsBack}
        // loadStepPois={loadStepPois}
      />
      <Poi />

      <Finish />
      <Pause />

      {/* Add Mini map... */}
      {/* <div ref={miniMapRef} id="miniMap" style={miniMapStyle} /> */}

      <div className="position-absolute z-2 w-100 d-flex justify-content-between" style={{ bottom: '50px' }}>
        <div className="d-flex w-100 ms-4 align-items-center">
          <div>
            <Timer
              timerInterval={timerInterval}
              pauseTimer={pauseTimer}
            />
            <Distance />
          </div>

          <div className="mx-auto">
            <button onClick={newPoi}
              className="btn btn-light"
            >
              {t('add poi')}
            </button>
            
            <PoiButton />
          </div>

          <SpeedSlider />
        </div>
      </div>

      <div ref={mapRef} id="map" style={mapStyle} />
    </>
  )
}
