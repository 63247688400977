import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "./Views/Loader";
import axios from "axios";
import { b2cPolicies, loginRequest } from "../authConfig";
import { useNavigate, Link } from 'react-router-dom';
import callApi from "../utils/ApiCall";
import { submitSuccess, capitalizeFirstLetter } from './Helpers'
import { useTranslation } from "react-i18next"

export default function Register({ instance, activateAccount }) {
  // const instance = new PublicClientApplication(msalSignUpSignInConfig);
  const { token } = useParams();
  const [ checkingInvitation, setCheckingInvitation ] = useState(true)
  const [ invitationValid, setInvitationValid ] = useState(false)
  const [ oid, setOid ] = useState(null)
  const [ accountActive, setAccountActive ] = useState(false)
  const [ account, setAccount ] = useState(null)
  const [ acceptedError, setAcceptedError ] = useState(null)

  const navigate = useNavigate()
  const { t, i18n } = useTranslation()


  useEffect(() => {
    // console.log(token);
    async function checkInvitation() {
      await axios.get(`${process.env.REACT_APP_API_URL}/api/invitation/${token}`)
        .then(async (response) => {
          if (response.data.success) {
            setInvitationValid(true)

            const { account } = response.data
            setAccount(account)
            i18n.changeLanguage(account.language_code)
            return
          }
          return Promise.reject(response);
        })
        .catch((error) => {
          console.log(error);
          setTimeout(() => {
            navigate('/')
          }, 3000);
        });

      setCheckingInvitation(false)
    }

    checkInvitation()

    const account = instance.getActiveAccount()
    // console.log('activateAccount: ', activateAccount);
    if (activateAccount && account) {
      acceptInvitation(account.idTokenClaims.oid, account.username)
    }

    async function acceptInvitation(oid, email) {
      setOid(oid)
  
      await callApi(`/api/invitation/${token}/accept`, 'PUT', {
        b2c_identifier: oid,
        email
      })
        .then((response) => {
          console.log(response);
          if (submitSuccess(response)) {
            setAccountActive(true)
            setTimeout(() => {
              navigate('/')
            }, 3000);
          }
        })
        .catch(async (error) => {
          // console.error(await error.json());
          const errorResponse = await error.json()
          setAcceptedError(errorResponse.error)
        });
    }
  }, [activateAccount, instance, token, navigate, setAccount, i18n])

  function requestSignUp() {
    // instance.clearCache()
    // const signUpRequest = { ...loginRequest, prompt: 'select_account', state: 'activate_account' }
    const signUpRequest = {
      ...loginRequest,
      prompt: 'select_account',
      state: 'activate_account',
      authority: b2cPolicies.authorities.signUpSignIn.authority,
    }

    instance.loginRedirect(signUpRequest)
    // instance.loginPopup(selectAccountRequest)
    //   .then(async (tokenResponse) => {
    //       console.log(tokenResponse);
    //       console.log(instance.getAllAccounts());
    //       if (!tokenResponse) {
    //       } else {
    //         const account = instance.getActiveAccount();
    //         console.log('account: ', account);
    //         activateAccount(account.idTokenClaims.oid, account.username)
    //       }
    //   }).catch((err) => {
    //       // Handle error
    //       console.error(err);
    //   });
  }

  if (checkingInvitation) {
    return (
      <Loader />
    )
  } else {
    if (invitationValid) {
      return (
        <div className='text-center mx-auto my-5 w-50 mx-auto'>
          <div className="d-inline-flex align-items-center mb-5 text-decoration-none">
            <img src='/static/img/logo_website.png' alt='Logo Memomove' />
            <h2 className='ms-2 mb-0'>
              Memomove
            </h2>
          </div>

          {accountActive && (
            <>
              <h4>
                {/* Your account has been activated */}
                { t('account activated' )}
              </h4>

              <div className="mt-8 p-4 rounded-3 border">
                <p className="mb-4">
                  {/* Redirecting to homepage... */}
                  { t('redirecting to homepage') }
                </p>
                <Loader />
              </div>
            </>
          )}

          {acceptedError && (
            <>
              {/* <h4>Something went wrong.</h4> */}
              <h4>{ t('something went wrong')}</h4>
              <div className="mt-8 alert alert-danger">
                {/* <p className="">There seems to be a server error. Please contact the support.</p> */}
                <p className="">{ t('server error') }</p>
                <Link to="/" className="btn bg-white border">
                  {/* Return to home */}
                  { capitalizeFirstLetter(t('back to index')) }
                </Link>
              </div>
            </>
          )}

          {!accountActive && !acceptedError && (
            <>
              <h3>
                {/* Activate your account */}
                { t('activate account') }
              </h3>

              {oid ? (
                <div className="mt-8 p-4 rounded-3 border text-center">
                  <Loader />
                  <p>
                    {/* Activating account... */}
                    { t('activating account') }
                  </p>
                </div>
              ) : (
                <div className="mt-8 p-4 rounded-3 border">
                  <b className="mb-4 d-block">
                    {/* You have been invited to join Memomove by organisation:  */}
                    { t('invitation intro') }
                    {account.organisation_name}
                  </b>

                  <p>
                    {/* Click the button below to create a new or use an existing Microsoft account to accept your invitation and access Memomove. */}
                    { t('activation description') }
                  </p>

                  <button onClick={() => requestSignUp()} className='mt-4 btn btn-outline-dark d-flex align-items-center mx-auto rounded-0'>
                    {/* <img
                      src='https://learn.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-apps/ms-symbollockup_mssymbol_19.svg'
                      alt='Microsoft Logo'
                      className='me-2'
                    /> */}
                    {/* Sign in with Microsoft */}
                    { t('create login') }
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      )
    } else {
      return (
        <div className='text-center mx-auto my-5'>
          <div className="d-inline-flex align-items-center mb-5 text-decoration-none">
            <img src='/static/img/logo_website.png' alt='Logo Memomove' />
            <h2 className='ms-2 mb-0'>
              Memomove
            </h2>
          </div>

          <h3 className=''>
            {/* Invalid token */}
            { t('registration token invalid') }
          </h3>
          <div className="mt-8 p-4 rounded-3 border">
            <p className="mb-4">
              {/* Redirecting to homepage... */}
              { t('redirecting to homepage')}
            </p>
            <Loader />
          </div>
        </div>
      )
    }
  }
}
