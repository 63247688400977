import React from "react";
// import SettingsStore from "../../stores/SettingsStore";
// import DashboardStore from "../../stores/DashboardStore";
// import StatsStore from "../../stores/StatsStore";
// import AndroidStore from "../../stores/AndroidStore";
// import MovingStore from "../../stores/MovingStore";
// import RouteStore from "../../stores/RouteStore";
// import MovementActions from "../../actions/MovementActions";
import callApi from '../../../utils/ApiCall'
// import StreetView from "./StreetView";
import StreetViewPlayer from "./StreetViewPlayer"
import Loader from "../../Views/Loader"
import RouteInfo from "./RouteInfo"

/** Main cycling interface container **/
export default class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isLoadingStartLocation: true,
      isLoadingSteps: true,
      current_step: null,
      totalDistanceMoved: 0,
      isEnd: false,
      allowMoveForward: true,
      startLocation: null,
      waypoints: [],
      overview_path: [],
      result: [],
      steps: [],
      routeFound: false,
      // showMap: DashboardStore.getShowMap(),
      // liteMode: SettingsStore.getLiteModeEnabled(),
      // memorideversion: SettingsStore.getMemorideVersion()
    };
    // this._onDashboardChange = this._onDashboardChange.bind(this)
    // this.setNextStep = this.setNextStep.bind(this)

    this.current_step_index = 0
    // this.current_step = null
    // this.totalDistanceMoved = 0
  }

  async componentDidMount() {
    const { id } = this.props;
    await this.loadRoute(id)
  }

  // componentWillUnmount() {
  // }

  // componentDidUpdate() {
  //   StatsStore.stopTimer()
  // }

  async loadRoute(id) {
    const that = this

    const { authType } = this.props
    let url
    if (authType === 'Manager') {
      url = `/api/v2/manager/routes/${id}`
    } else {
      url = `/api/v2/routes/${id}`
    }

    await callApi(url)
      .then(function (response) {
        if (!response.data) {
          return
        }

        // console.log('loadRoute: ', response);

        that.setState({
          startLocation: response.data.start_point,
          endLocation: response.data.end_point,
          waypoints: response.data.waypoints,
          // overview_path: response.data.overview_path.flat(),
          // result: response.data.result.reverse()
          description: response.data.description,
          distance: response.data.distance,
          internal_distance: response.data.internal_distance,
          steps: response.data.steps
        }, () => {
          // that.setState({ isLoadingStartLocation: false })
          // Handle route not found...
          if (!that.state.steps) {
            that.setState({ isLoadingSteps: false })
          } else {
            that.setState({ current_step: that.state.steps[0], routeFound: true, isLoadingSteps: false })
            // StatsStore.startTimer()
            // console.log('Starting route...');
            // const { description, internal_distance } = that.state
    
            // that.props.setRouteInfoData(true, { description, internal_distance})
          }
          // that.setLastRun(id)
          // that.current_step = that.state.steps[0]
        })

      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse);
        that.setState({ isLoadingSteps: false })
      });
  }

  // setNextStep() {
  //   // console.log('settings Next Step...');
  //   // console.log(this.state.current_step);

  //   this.current_step_index += 1
  //   const nextStep = this.state.steps[this.current_step_index]
  //   const that = this

  //   this.setState({
  //     current_step: nextStep,
  //     totalDistanceMoved: parseFloat(this.state.totalDistanceMoved) + parseFloat(nextStep.distance_moved),
  //     isEnd: this.current_step_index === this.state.steps.length - 1,
  //     allowMoveForward: this.current_step_index !== this.state.steps.length - 1,
  //   }, () => {
  //     that.checkEnd()
  //   })
  // }

  async checkEnd() {
    if (this.state.isEnd) {
      await this.setLastRun()
      // RouteStore.setReplayOff()
      // StatsStore.stopTimer()
    }
  }

  async setLastRun() {
    const { id, authType } = this.props
    if (authType === 'Manager') return

    const url = `/api/routes/${id}`

    await callApi(url, 'PUT', {})
      .then(function (response) {
        // console.log(response)
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        console.error(errorResponse)
      });
  }

  // _onDashboardChange() {
  //   this.setState({
  //     showMap: DashboardStore.getShowMap()
  //   });
  // }

  render() {
    const { isLoadingSteps, routeFound } = this.state
    const { t } = this.props

    if (isLoadingSteps) {
      return (
        <div className="h-100 d-flex align-items-center">
          <Loader />
        </div>
      )
    } else {
      if (!routeFound) {
        return (
          <div className='w-100 d-flex align-items-center justify-content-center'>
            <div className="my-5 card mx-auto p-4">
              <h1>{t('route not found')}</h1>
              <p>
                {/* Something went wrong. Please contact the support. */}
                {t('something went wrong')} 
              </p>
              <a href="mailto:info@memomove.eu">{t('contact support')}</a>
            </div>
          </div>
        )
      } else {
        return (
          <>
            <RouteInfo
              route={{
                description: this.state.description,
                internal_distance: this.state.internal_distance
              }}
            />
            <div id="cycle" className="h-100 w-100 position-relative">
              <StreetViewPlayer
                steps={this.state.steps}
                id={this.props.id}
                authType={this.props.authType}
                orgUuid={this.props.orgUuid}
              />
            </div>
          </>
        )
      }
    }
  }
}
