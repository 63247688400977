import { useRef, useEffect, useCallback } from "react";
import MemoStore from "../../../stores/MemoStore";
import DirectionsStore from "../../../stores/DirectionsStore";
import CheckBox from "../../Form/CheckBox";

export default function MiniMap({ routePoints, directionsData, routeTravelMode, authType }) {
  const mapRef = useRef()
  const positionMarker = useRef()
  const markers = useRef([])
  const currentPosition = useRef()
  const directions = useRef()
  const map = useRef()

  const directionsRequestCallback = useCallback( async(result) => {
    return result
  }, [])

  const setStepMarker = useCallback((position) => {
    const marker_type = '/static/img/measle.png';
    const marker = new window.google.maps.Marker({
      position: position,
      draggable: false,
      icon: marker_type
    });
    marker.setIcon(marker_type);

    markers.current.push(marker)
  }, [])

  const setMapOnAll = useCallback((map) => {
    for (let i = 0; i < markers.current.length; i++) {
      markers.current[i].setMap(map);
    }
  }, [])
  
  // Removes the markers from the map, but keeps them in the array.
  const hideMarkers = useCallback(() => {
    setMapOnAll(null);
  }, [setMapOnAll])
  
  // Shows any markers currently in the array.
  const showMarkers = useCallback(() => {
    setMapOnAll(map.current);
  }, [setMapOnAll, map])

  const displayDirections = useCallback(() => {
    if (!DirectionsStore.getDirections()) {
      DirectionsStore.setDirectionsData(directions.current)
    }

    const polylineOptions = {
      strokeColor: '#99ff00', //red:#FF0000 , greenish:#99ff00
      strokeOpacity: 0.7,
      strokeWeight: 10
    }

    const directionsDisplay = new window.google.maps.DirectionsRenderer({
      polylineOptions: polylineOptions,
      suppressBicyclingLayer: false
    })

    directionsDisplay.setMap(map.current);
    directionsDisplay.set('directions', directions.current)
  }, [])

  const drawRoute = useCallback(async () => {
    const waypoints = [];

    if (directions.current) {
      displayDirections()
      return
    }

    if (routePoints.length === 0) return

    // Create the DirectionsRequest object
    for (let i = 1; i < routePoints.length - 1; i++) {
      waypoints.push({
        location: new window.google.maps.LatLng({lat: routePoints[i].lat, lng: routePoints[i].lng})
      })
    }

    console.log('routeTravelMode: ', routeTravelMode)
    const travelMode = routeTravelMode === 'bicyling' ? 'BICYCLING' : 'DRIVING'
    // const travelMode = window.google.maps.TravelMode.BICYCLING

    const lastDirectionsRequest = {
      origin: new window.google.maps.LatLng({lat: routePoints[0].lat, lng: routePoints[0].lng}),
      destination: new window.google.maps.LatLng({
        lat: routePoints[routePoints.length - 1].lat,
        lng: routePoints[routePoints.length - 1].lng
      }),
      waypoints: waypoints,
      provideRouteAlternatives: false,
      // travelMode: window.google.maps.TravelMode.DRIVING, //DRIVING, BICYCLING, TRANSIT, WALKING
      travelMode: travelMode, //DRIVING, BICYCLING, TRANSIT, WALKING
      unitSystem: window.google.maps.UnitSystem.METRIC, //METRIC, IMPERIAL
      avoidFerries: true,
      avoidHighways: true,
      avoidTolls: true,
    };
  
    // Request the route
    const directionsService = new window.google.maps.DirectionsService()
    const directionsResult = await directionsService.route(
      lastDirectionsRequest,
      directionsRequestCallback
    )
    directionsResult.generated = true
    console.log('directionResult: ', directionsResult)
    directions.current = directionsResult

    displayDirections()
  }, [displayDirections, routePoints, routeTravelMode, directionsRequestCallback]);

  const updateCurrentPosMarker = useCallback((map) => {
    currentPosition.current = MemoStore.getCurrentPosition()

    // console.log('currentPosition.current: ', currentPosition.current);

    const { lat, lng, heading } = { ...currentPosition.current }
    const new_position = new window.google.maps.LatLng({ lat, lng });

    if (!positionMarker.current) {
      const pacman = {
        path: 'm 44.628665,11.550202 c -4.658,20.56999 -23.77859,35.6452 -44.91945064,35.41576 -12.97638936,-0.14083 -22.69260936,-4.28153 -31.87558936,-13.58422 -9.56053,-9.68516 -13.59915,-19.70194 -13.39956,-33.23420994 0.1661,-11.26214006 4.39171,-21.97507006 11.84198,-30.02230006 l 3.28091,-3.5438 6.21184,6.72329 c 3.41651,3.6978 10.27863,11.19879 15.2491494,16.66887 L 0.05525436,-0.08080794 6.1457843,-5.4337079 c 3.3498004,-2.9441 10.9654907,-9.5986901 16.9237707,-14.7879901 l 10.83323,-9.43508 2.36099,2.79351 c 3.57444,4.22926 7.47983,13.02496 8.63459,19.4467801 1.25505,6.97957996 1.17535,12.5852599 -0.2697,18.9666899 z',
        fillColor: '#ff38fd',
        fillOpacity: 0.8,
        scale: 0.3,
        strokeColor: '#983c97',
        rotation: heading,
        strokeWeight: 1
      };
      positionMarker.current = new window.google.maps.Marker({
        position: new_position,
        icon: pacman,
        map: map,
        draggable: false
      });
    } else {
      const icon = positionMarker.current.getIcon();
      icon.rotation = heading;
      positionMarker.current.setPosition(new_position);
      positionMarker.current.setIcon(icon);
    }

    // mapRef.current.panTo(new_position);
    map.panTo(new_position);
  }, [currentPosition])

  const updateStepMarkers = useCallback(() => {
    // console.log('updateStepMarkers')
    const steps = DirectionsStore.getDirectionSteps()
    // const steps = directions.current
    // console.log('steps: ', steps);
    steps.forEach(point => {
      setStepMarker({ lat: point.lat, lng: point.lng })
    });
    // if (authType === 'Manager') {
    setTimeout(() => {
      showMarkers()
    }, 200);
    // }
  }, [setStepMarker, authType, showMarkers])

  const createMap = useCallback(async (startPosition) => {
    // const settings = SettingsStore.getSettings();
    // console.log('createMap');

    // newCenter = 
    const mapOptions = {
      center: new window.google.maps.LatLng(startPosition),
      zoom: 8,
      disableDefaultUI: true,
      panControl: false,
      zoomControl: false,
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL, //LARGE
        position: window.google.maps.ControlPosition.LEFT_TOP
      },
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      clickableIcons: false,
    };

    // console.log('mapRef: ', mapRef.current);
    // console.log(typeof mapRef.current);

    map.current = new window.google.maps.Map(mapRef.current, mapOptions);
    // console.log(map);
    
    window.google.maps.event.addListenerOnce(map.current, "idle", async () => {
      // mapRef.current = map
      // ...
      const streetViewLayer = new window.google.maps.StreetViewCoverageLayer();
      // streetViewLayer.setMap(mapRef.current);
      streetViewLayer.setMap(map.current);

      MemoStore.addPositionChangeListener(() => {
        // console.log('map: ', map.current);
        updateCurrentPosMarker(map.current)
      }, [map])

      if (directionsData && directionsData.length > 0) {
        directions.current = directionsData
        console.log('directions.current: ', directions.current);
      }
  
      await drawRoute(map.current)
    });
  }, [directionsData, drawRoute, updateCurrentPosMarker])

  const init = useCallback(() => {
    createMap(routePoints[0])
  }, [createMap, routePoints])

  const toggleMarkers = useCallback((checked) => {
    checked ? showMarkers() : hideMarkers()
  }, [showMarkers, hideMarkers])
  
  useEffect(() => {
    init()

    if (directionsData) {
      directions.current = directionsData
      // updateStepMarkers(map)
    }

    DirectionsStore.addDirectionStepsChangeListener(() => {
      updateStepMarkers()
    })

    return () => {
      DirectionsStore.removeDirectionStepsChangeListener(() => {
        updateStepMarkers()
      })
      MemoStore.removePositionChangeListener(() => {
        updateCurrentPosMarker(map.current)
      }, [map])
    }
  }, [directionsData, init, toggleMarkers, updateStepMarkers, updateCurrentPosMarker])

  const divStyle = {
    zIndex: 10,
    bottom: 20,
    left: 20,
    borderRadius: 8
    // height: '400px'
  }

  const mapStyle = {
    width: '200px',
    height: '200px',
    borderRadius: 8
  }

  return (
    <small className="position-absolute" style={divStyle}>
      <div ref={mapRef} id="miniMap" style={mapStyle} />
      {/* {authType === 'Manager' && ( */}
        <div className="bg-white mt-2 d-flex px-2 justify-content-between align-items-center">
          <CheckBox
            onChange={(checked) => toggleMarkers(checked)}
            label='Toggle points'
            defaultValue={true}
          />
        </div>
      {/* )} */}
    </small>
  )
}