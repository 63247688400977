import Loader from "../../Views/Loader"
import { Wrapper, Status } from "@googlemaps/react-wrapper"
import Map from './Map'

export default function StreetViewPlayer({ steps, id }) {
  const mapRender = (status) => {
    return (
      <div className='h-100 d-flex align-items-center'>
        {status === Status.FAILURE ? (
          // 'Error loading the map.'
          'Map error'
        ) : (
          <Loader />
        )}
      </div>
    )
    // switch (status) {
    //   case Status.LOADING:
    //     return <Loader />;
    //   case Status.FAILURE:
    //     return 'Error loading the map.';
    //   case Status.SUCCESS:
    //     return <Map />;
    //   default:
    //     return ''
    // }
  };

  return (
    <Wrapper apiKey={process.env.REACT_APP_MAPS_API_KEY} render={mapRender}>
      <Map
        steps={steps}
        id={id}
      />
    </Wrapper>
  )
}