import { useState, useEffect } from "react"
import Loader from "../Views/Loader"
import OrganisationsList from "./OrganisationsList"
import callApi from "../../utils/ApiCall"
// import moment from "moment"

export default function Organisations({ setOrganisation, setIsLoadingOrganisation }) {
  const [ organisations, setOrganisations ] = useState([])
  const [ isLoadingOrganisations, setIsLoadingOrganisations ] = useState(true)

  // function prepareOrganisations(organisations) {
  //   return organisations.map((organisation) => {
  //     return {
  //       ...organisation,
  //       end_date: moment(organisation.end_date).local().format('DD/MM/YYYY')
  //     }
  //   })
  // }

  useEffect(() => {
    async function fetchOrganisations() {
      if (organisations.length !== 0) return
  
      await callApi(`/organisations`)
        .then(function (response) {
          // setOrganisations(prepareOrganisations(response.data.organisations))
          if (!response.data.organisations) return
          setOrganisations(response.data.organisations)
        })
        .catch(async function (error) {
          const errorResponse = await error.json()
          console.error(errorResponse);
        });
      setIsLoadingOrganisations(false)
    }
    
    fetchOrganisations()

  }, [organisations, isLoadingOrganisations])
  

  return (
    <>
      <h1 className="mb-4">Select a organisation</h1>
      {isLoadingOrganisations ? (
        <Loader />
      ) : (
        <OrganisationsList
          organisations={organisations}
          setOrganisation={setOrganisation}
          setIsLoadingOrganisation={setIsLoadingOrganisation}
        />
      )}
    </>
  )
}