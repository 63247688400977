// import React from 'react';
import { useEffect } from "react";
import { Link } from "react-router-dom";
import OrganisationDashboard from "./OrganisationDashboard";
import { truncateString, capitalizeFirstLetter } from "../Helpers";
import { useTranslation } from 'react-i18next';

export default function Dashboard({ setActiveNav, organisation }) {  
  const { t } = useTranslation('translation');

  useEffect(() => {
    setActiveNav('Home')
  }, [setActiveNav])
  
  return (
    <>
      <div className="row">
        <h1>
          {organisation ?
            (
              <>
                {capitalizeFirstLetter(t('organisation'))}: {truncateString(organisation.name, 32)}
              </>
            )
            :
            <>
              {t('Please select a organisation') }
            </>
          }
        </h1>
        {organisation ? (
          <OrganisationDashboard
            organisation={organisation}
          />
        ) : (
          <div className="w-25">
            <Link to='organisations' className="btn btn-primary">
              View organisations
            </Link>
          </div>
        )}
      </div>
    </>
  )
}
