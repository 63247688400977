export default function RouteInfo({ route }) {
  return (
    <div className="d-flex px-4 py-2 bg-white align-items-center position-absolute z-3 w-100" style={{ bottom: 0 }}>
      <small className="me-2">
        {route.internal_distance}m
      </small>
      <b className="">
        {route.description}
      </b>
    </div>
  )
}
