/* eslint-disable no-loop-func */
import { useRef, useEffect, useCallback, useState } from 'react'
import { defaultToast, NearestLocation, bearingToPoint, calcDistance } from '../../Helpers'
import MemoStore from '../../../stores/MemoStore'
import DirectionsStore from '../../../stores/DirectionsStore'
// import { useTranslation } from 'react-i18next';

export default function Map ({ directionsData }) {
  const [showMap, setShowMap] = useState(true)
  const ref = useRef()
  // const center = useRef()

  let panorama = useRef()
  let lastForwardAngle = useRef(0)
  const directions = useRef()
  const distanceFromLast = useRef(0)
  const routeSteps = useRef()
  const currentPointIndex = useRef(0)

  let lastPosition = useRef(undefined)
  let distanceMoved = useRef(0)
  let lastPoint = useRef(null)
  const sv = useRef(null)
  const statusChangeListener = useRef()

  const onKey = useCallback((e) => {
    e.stopPropagation()

    // window.addEventListener(
    //   'keydown',
    //   (event) => {
    //     if (
    //       (
    //         // Change or remove this condition depending on your requirements.
    //         event.key === 'ArrowUp' || // Move forward
    //         event.key === 'ArrowDown' || // Move forward
    //         event.key === 'ArrowLeft' || // Pan left
    //         event.key === 'ArrowRight' || // Pan right
    //         event.key === '+' || // Zoom in
    //         event.key === '=' || // Zoom in
    //         event.key === '_' || // Zoom out
    //         event.key === '-' // Zoom out
    //       ) &&
    //       !event.metaKey &&
    //       !event.altKey &&
    //       !event.ctrlKey
    //     ) {
    //       event.stopPropagation()
    //     };
    //   },
    //   { capture: true },
    // );

    const intKey = (window.Event) ? e.which : e.keyCode;
    switch (intKey) {
      case 38:
      case 87:
      case 90:
        break;
      default:
        break;
    }
  }, [])

  const setPanoramaV2 = useCallback((step) => {
    // console.log('setPanoramaV2');
    MemoStore.setPanoStatus('OK')

    sv.current.getPanorama({
      location: step,
      preference: 'best',
      sources: ['outdoor']
    }).then((data, status) => {
      // console.log('data: ', data);
      // console.log('status: ', status);
      // console.log('setPanorama: ', step)
      setTimeout(async() => {
        panorama.current.setPosition(step)

        panorama.current.setPov(
          /** @type {google.maps.StreetViewPov} */ {
            // heading: 265,
            heading: step.heading,
            pitch: 0,
          },
        )
        panorama.current.setVisible(true)
        lastForwardAngle.current = step.heading
        MemoStore.setCurrentPosition({
          lat: step.lat,
          lng: step.lng,
          heading: step.heading
        })
      }, 1500)
    }).catch((e) => {
      console.error("Street View data not found for this location.")
      // alert('No valid pano found... Skip')
      MemoStore.setPanoStatus(false)

      currentPointIndex.current++
      // console.log('currentPointIndex: ', currentPointIndex.current);
      const nextPoint = routeSteps.current[currentPointIndex.current]
      if (nextPoint) {
        setPanoramaV2(nextPoint)
      } else {
        MemoStore.setEndStatus(true)
      }
    });
  }, [])

  const findNextPanoV2 = useCallback(async(currentPosition) => {
    lastPosition.current = {
      lat: currentPosition.lat(),
      lng: currentPosition.lng()
    }
    currentPointIndex.current++
    const nextPoint = routeSteps.current[currentPointIndex.current]

    if (nextPoint) {
      setPanoramaV2(nextPoint)
    } else {
      // console.log('At the end..')

      MemoStore.setEndStatus(true)
    }
  }, [setPanoramaV2])

  const panoChanged = useCallback(async () => {
    const currentPanorama = panorama.current
    const currentPosition = currentPanorama.getPosition()

    const pos = {
      lat: currentPosition.lat(),
      lng: currentPosition.lng()
    }

    const pov = currentPanorama.getPov()

    currentPosition.heading = pov.heading
    currentPosition.pitch = pov.pitch
    currentPosition.pano = currentPanorama.getPano()

    // console.log(currentPanorama);

    // Check distance from last position...
    if (lastPosition.current) {
      distanceFromLast.current = calcDistance(lastPosition.current, pos)
      distanceMoved.current = distanceMoved.current + distanceFromLast.current
      DirectionsStore.addStepDistance(distanceFromLast.current)
    }

    const currentStep = {
      heading: currentPosition.heading,
      pitch: 0,
      pano: currentPosition.pano,
      location: pos,
      distanceMoved: distanceFromLast.current
    }

    // console.log('currentStep: ', currentStep);

    MemoStore.addStep(currentStep)
    
    setTimeout(async() => {
      await findNextPanoV2(currentPosition)
    }, 1000);
  }, [findNextPanoV2])

  const initPanorama = useCallback(() => {
    // console.log('map: ', map);
    console.log('initPanorama');
    const currentPanorama = panorama.current
    currentPanorama.setOptions({
      addressControl: false,
      imageDateControl: false,
      clickToGo: false,
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      fullscreenControl: false,
      scrollWheel: false,
      panControl: false,
      motionTracking: false,
      zoomControl: false,
      enableCloseButton: false,
      keyboardShortcuts: false,
      panningGesturesEnabled: false,
      zoomGesturesEnabled: false
    }) // TODO fix type

    statusChangeListener.current = panorama.current.addListener("status_changed", panoChanged)

    return () => {
      currentPanorama.removeListener("status_changed", panoChanged)
    }
  }, [panoChanged])

  const toggleStreetViewV2 = useCallback(() => {
    // console.log('toggleStreetViewV2');
    const startLocation = routeSteps.current[currentPointIndex.current]

    lastPoint.current = startLocation
    
    if (!sv.current) {
      sv.current = new window.google.maps.StreetViewService()
    }
    if (!panorama.current) {
      panorama.current = new window.google.maps.StreetViewPanorama(ref.current)
    }

    initPanorama()
    setPanoramaV2(startLocation)

    lastForwardAngle.current = startLocation.heading
  }, [initPanorama, setPanoramaV2])

  const createMap = useCallback(async() => {
    if (routeSteps.current.length === 0) return

    toggleStreetViewV2()
  }, [routeSteps, toggleStreetViewV2])

  const generatePoint = useCallback((a, b, ratio) => {
    return {
      lat: a.lat + (b.lat - a.lat) * ratio,
      lng: a.lng + (b.lng - a.lng) * ratio,
    }
  }, [])

  const generatePointsBetween = useCallback((a, b) => {
    if (a.distance < 11) return

    const betweenList = []
    const n = Math.round(a.distance / 10)
    for (let i = 1; i < n; i++) {
      const ratio = i / n
      betweenList.push(generatePoint(a, b, ratio))
    }
    
    return betweenList
  }, [generatePoint])

  const setHeadings = useCallback((list) => {
    for (let j = 0; j < list.length; j++) {
      const p1 = list[j]
      const p2 = list[j+1]
      if (p2) {
        p1.distance =  calcDistance(p1, p2)
        p1.heading = bearingToPoint(p1, p2)
      } else {
        p1.heading = list[j-1].heading
      }
    }
    return list
  }, [])

  const cleanPoints = useCallback((list) => {
    let copyList = [ ...list ]
    const indexesToRemove = []
    // let removedPrev = false
    // let removed2 = false
    // copyList.forEach(point => {
    for (let index = 0; index < copyList.length; index++) {
      // 1. Clear points with 0 distance & 0 heading
      const point = copyList[index]
      const nextPoint = copyList[index+1]
      // if (point.heading === 0 || point.distance < 5) {
        if (point.heading === 0 && point.distance === 0) {
          indexesToRemove.push(index)
        } else if (nextPoint && point.distance < 5 && nextPoint > 5) {
          indexesToRemove.push(index)
        }
      // if (nextPoint && point.distance < 5 && !removedPrev) {
      //   indexesToRemove.push(index)
      //   removedPrev = true
      // } else if (nextPoint && nextPoint.distance < 5 && removedPrev && !removed2 && point.distance < 5) {
      //   indexesToRemove.push(index)
      //   removedPrev = true
      //   removed2 = true
      // } else {
      //   removedPrev = false
      // }
    }

    if (indexesToRemove.length > 0) {
      indexesToRemove.toReversed().forEach(index => {
        copyList.splice(index, 1)
      });
    }
    copyList = setHeadings(copyList)

    return copyList
  }, [setHeadings])

  const checkForPoints = useCallback((list) => {
    let listCopy = [...list]
    let spliceIndex = 1
    for (let index = 0; index < list.length; index++) {
      const p1 = { ...list[index] }
      const p2 = { ...list[index+1] }

      if (p2) {
        const pointsBetween = generatePointsBetween(p1, p2)
        if (pointsBetween) {
          listCopy.splice(spliceIndex, 0, ...pointsBetween)
          spliceIndex += pointsBetween.length
        }
        spliceIndex += 1
      }
    }

    listCopy = setHeadings(listCopy)

    // console.log('beforeClean: ', listCopy);
    const finalList = cleanPoints(listCopy)
    // console.log('finalList: ', finalList);

    DirectionsStore.setDirectionSteps(finalList)
    routeSteps.current = finalList
    createMap()
  }, [generatePointsBetween, createMap, setHeadings, cleanPoints])

  const generatePointList = useCallback(() => {
    // console.log('generatePointList');
    let currentPoint
    let list = []

    // directions.current.forEach(leg => {
    directions.current.routes[0].legs.forEach(leg => {
      leg.steps.forEach(step => {
        for (let k = 0; k < step.lat_lngs.length; k++) {
          if (directions.current.generated) {
            currentPoint = { lat: step.lat_lngs[k].lat(), lng: step.lat_lngs[k].lng() }
          } else {
            currentPoint = { lat: step.lat_lngs[k].lat, lng: step.lat_lngs[k].lng }
          }
          if (step.lat_lngs[k+1]) {
            let nextPoint
            if (directions.current.generated) {
              nextPoint = { lat: step.lat_lngs[k+1].lat(), lng: step.lat_lngs[k+1].lng() }
            } else {
              nextPoint = { lat: step.lat_lngs[k+1].lat, lng: step.lat_lngs[k+1].lng }
            }
            currentPoint.distance = calcDistance(currentPoint, nextPoint)
            currentPoint.heading = bearingToPoint(currentPoint, nextPoint)
          }
          list.push(currentPoint)
        }
      })
    })

    checkForPoints(list)
  }, [checkForPoints])
  
  useEffect(() => {
    console.log('map load...');

    // MemoStore.resetDistance()
    DirectionsStore.resetStepDistance()
    // DirectionsStore.addDirectionsChangeListener(generatePointList)

    if (directionsData) {
      directions.current = directionsData
    } else {
      directions.current = DirectionsStore.getDirections()
    }
    generatePointList()

    window.addEventListener('keydown', (event) => onKey(event), { capture: true })

    return () => {
      console.log('will unmount');
      setShowMap(false)
      window.google.maps.event.removeListener(statusChangeListener.current);
      // DirectionsStore.removeDirectionsChangeListener(generatePointList)
      window.removeEventListener('keydown', (event) => onKey(event), { capture: true })
    }
  }, [directionsData, generatePointList, setShowMap, onKey])

  const divStyle = {
    width: 'auto',
    height: '100%'
    // height: '400px'
  }

  return (
    <>
      {showMap && (
        <div ref={ref} id="map" style={divStyle} />
      )}
    </>
  )
}
