import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from "react-router-dom"
import { submitSuccess, submitError, successToast, errorToast, licenseType, capitalizeFirstLetter } from '../Helpers'
import callApi from '../../utils/ApiCall'
import { useParams, Link } from 'react-router-dom'
import Loader from '../Views/Loader'
import AccountForm from './AccountForm'
import { useTranslation } from 'react-i18next';

export default function EditAccount({ organisation, authType }) {
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formErrorFields, setFormErrorFields] = useState([]);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { t } = useTranslation('translation');

  let url
  if (authType === 'Admin') {
    url = `/accounts/${uuid}`
  } else {
    url = `/organisations/${organisation.uuid}/accounts/${uuid}`
  }

  useEffect(() => {
    callApi(url)
      .then(function (response) {
        // console.log(response);
        const loadedFormData = response.data
        setAccount({
          ...loadedFormData,
          account_type: loadedFormData.type
        })
        setLoading(false)
      })
      .catch(async function (error) {
        const errorResponse = error.json()
        console.error(errorResponse);
      });
  }, [url, organisation])

  const handleSubmit = useCallback((accountData) => {
    callApi(url,
      'PUT',
      accountData
    )
      .then(function (response) {
        // console.log(response)
        if (submitSuccess(response)) {
          navigate("/accounts")
          successToast(t('account updated'))
        }
      })
      .catch(async function (error) {
        if (submitError(error)) {
          const response = await error.json()
          // console.log(response);
          errorToast(response.message)
          const errorFields = [ ...response.fields ]
          errorFields.forEach(error => {
            if (error.field === 'organisation') {
              error.message = t('active accounts limit', { organisation_name: organisation.name, license_name: licenseType(organisation.license_type), allowed_accounts: organisation.allowed_accounts })
              // `You are a member of the organisation ${organisation.name} and the license type is ${organisation.license_name}. ` +
              // `This means your organisation is allowed to ${organisation.allowed_accounts} active users and that number has been reached.`
            }
          })

          setFormErrorFields(errorFields)
        }
      });
  }, [url, navigate, organisation, t])
  
  return (
    <>
      <div className='row'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/accounts">Accounts</Link></li>
            <li className="breadcrumb-item active" aria-current="page">
              {t('edit account')}
            </li>
          </ol>
        </nav>
      </div>

      <div className='row'>
        <h1>
          {/* Edit account */}
          {t('edit account')}
        </h1>
        
        {loading ? (
          <Loader />
        ) : (
          <AccountForm
            formData={account}
            type="Edit"
            // valid={formValid}
            organisation={organisation}
            formErrorFields={formErrorFields}
            submitLabel={capitalizeFirstLetter(t('save'))}
            handleSubmit={handleSubmit}
            t={t}
          />
        )}
      </div>
    </>
  )
}
