import assign from 'object-assign'
import EventEmitter from 'events'

const POINTS_CHANGE = "onPointsChange"
const DISTANCE_CHANGE = "onDistanceChange"
const LAYER_CHANGE = "onLayerChange"
const DRIVING_TYPE_CHANGE = "onDrivingTypeChange"

let routePoints = []
let showLayer = true
let drivingType = 'driving'
let routeDistance = 0
let description = ''
let directionsResult = null
let countryCodes = []
let savingRoute = false
let routeSaved = false

const CreateStore = assign({}, EventEmitter.prototype, {
  getRoutePoints: function() {
    return routePoints
  },

  addRoutePoint: function(point) {
    routePoints.push(point)
    this.emit(POINTS_CHANGE)
  },

  setRoutePoints: function(points) {
    routePoints = points
  },

  removeRoutePoint: function(point) {
    const index = routePoints.findIndex(p => p === point)
    if (!routePoints[index]) return

    routePoints.splice(index, 1)
    this.emit(POINTS_CHANGE)
  },

  addRoutePointsChangeListener: function(callback) {
    this.on(POINTS_CHANGE, callback)
  },

  removeRoutePointsChangeListener: function(callback) {
    this.removeListener(POINTS_CHANGE, callback)
  },

  clearRoutePoints: function() {
    routePoints = []
  },

  getRouteDistance: function() {
    return routeDistance
  },

  setRouteDistance: function(distance) {
    routeDistance = distance
    this.emit(DISTANCE_CHANGE)
  },

  addRouteDistanceChangeListener: function(callback) {
    this.on(DISTANCE_CHANGE, callback)
  },

  removeRouteDistanceChangeListener: function(callback) {
    this.removeListener(DISTANCE_CHANGE, callback)
  },

  getShowLayer: function() {
    return showLayer
  },

  setShowLayer: function(show) {
    showLayer = show
    this.emit(LAYER_CHANGE)
  },

  addShowLayerChangeListener: function(callback) {
    this.on(LAYER_CHANGE, callback)
  },

  removeShowLayerChangeListener: function(callback) {
    this.removeListener(LAYER_CHANGE, callback)
  },

  getDrivingType: function() {
    return drivingType
  },

  setDrivingType: function(type) {
     drivingType = type
     this.emit(DRIVING_TYPE_CHANGE)
  },

  addDrivingTypeChangeListener: function(callback) {
    this.on(DRIVING_TYPE_CHANGE, callback)
  },

  removeDrivingTypeChangeListener: function(callback) {
    this.removeListener(DRIVING_TYPE_CHANGE, callback)
  },

  setDescription: function(newDescription) {
    description = newDescription
  },

  getDescription: function() {
    return description
  },

  setDirectionsResult: function(directions) {
    directionsResult = directions
  },
  
  getDirectionsResult: function() {
    return directionsResult
  },

  addCountryCode: function(code) {
    const codeExists = countryCodes.includes(code)
    if (codeExists) return
    
    countryCodes.push(code)
  },

  getCountryCode: function() {
    return countryCodes[0]
  },

  setSavingRoute: function(saving) {
    savingRoute = saving
  },

  getSavingRoute: function() {
    return savingRoute
  },

  setRouteSaved: function(saved) {
    routeSaved = saved
  },

  getRouteSaved: function () {
    return routeSaved
  }
})

export default CreateStore
